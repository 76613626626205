import { LOCALES } from "./locales";

export const messages: {[key: string]: {[key: string]: string}} = {
    [LOCALES.ENGLISH]: {
		reg_create_account: "Create your account",
		reg_continue_with: "Sign in with",
		reg_continue: "Sign in",
		reg_new_in: "New to Osmos?",
		reg_join_now: "Join now",
		reg_continue_with_email: "Continue with email",
		reg_your_email: "Your e-mail",
		reg_send_link: "Send the magic link",
		reg_check_email: "Check your email. We sent the magic link for authorization",
		reg_open_mailbox: "Open Mailbox",
		reg_ok_got_it: "Ok, I got it!",
		reg_auth_timeout: "Mail authorization time is out",
		reg_send_again: "Send another link",
		reg_error: "Something went wrong",
		reg_try_again: "Please, try again",
		reg_reload_page: "Reload the page",
		sign_up: "Sign up",
		sign_in: "Sign in",
		fname: "First name",
		fname_ph: "Your first name",
		lname: "Last name",
		lname_ph: "Your last name",
		next: "Next",
		skip: "Skip",
		message: "Message",
		or: "or",
		save_draft: "Save draft",
		settings: "Settings",
		settings_support: "Support",
		settings_support_email: "Email us if you have any questions",
		settings_profile_settings: "Profile settings",
		settings_profile_email_prefs: "Email preferences",
		settings_profile_email_pref_1: "Notify me about my matches in my email",
		settings_profile_email_pref_2: "Keep me updated with news about the project",
		settings_profile_email_pref_3: "Get platform updates",
		settings_profile_save: "Save changes",
		settings_profile_email_unsub: "Unsubscribe from all marketing emails",
		settings_profile_delete: "Delete account",
		settings_profile_delete_reason_0: "I have a duplicate account",
		settings_profile_delete_reason_1: "I'm getting too many emails",
		settings_profile_delete_reason_2: "I'm not getting any value from my membership",
		settings_profile_delete_reason_3: "I have a privacy concern",
		settings_profile_delete_reason_4: "I'm receiving unwanted contact",
		settings_profile_delete_reason_5: "Other",
		settings_profile_delete_hint_1: "You have an active subscription. Your subscription will be automatically renewed on",
		settings_profile_delete_hint_2: "You can cancel the auto-renewal at any time before your renewal date, however, no refunds are available within the active subscription period.",
		settings_profile_delete_final_1: "Thanks for your feedback",
		settings_profile_delete_final_2: "we are constantly improving",
		settings_profile_delete_final_3: "After deleting your account, you will no longer be able to access it. Your subscription will be cancelled, and you won't be charged for the upcoming period. However, we do not provide refunds for the remaining subscription period after you delete your account.",
		settings_logout: "Log out",
		settings_logout_stay: "Stay logged in",
		settings_logout_hint: "By logging out, you will be signed out of your current session",
		read_more: "Read more",
		nothing_found: "No results found",
		ob_onlylatin: "To broaden your match possibilities, ensure you only use the English language",
		ob_header_common: "Introduce yourself",
		ob_notify_matches: "Notify me about my matches in my email",
		ob_concent: "I consent to the",
		ob_processing_data: "processing of my personal data",
		ob_photo_header: "Add your photo",
		ob_photo_hint: "People prefer to build connections with those who have a photo",
		ob_add_from_lib: "Add from library",
		ob_intro_header: "Introduce yourself in a way that makes other professionals interested to connect with you.",
		ob_intro_hint: "What are you working on right now? What do you know better then anyone else? What can you help others with?",
		ob_intro_short_1: "Your bio seems a bit short, could you add some more details?",
		ob_intro_short_2: "Heading in the right direction! Yet, we could benefit from more details.",
		ob_intro_short_ok: "Your bio looks great, thank you for sharing!",
		ob_industry_header: "Choose industry you're in",
		industry_ph: "Start typing",
		industry_ph_40: "Search in 40+ industries",
        industry: "Industry",
		ob_occupation_header: "Add more details in your profile",
		ob_occupation_company: "Company",
		occupation_company_ph: "Enter the name of your company",
		ob_occupation_role: "Role",
		occupation_role_ph: "Enter the role in the company",
		ob_occupation_city: "Your city",
		city_ph: "Enter the city you live in",
		match: "Match",
		match_are_you_ready: "If you are ready for 1:1\u00A0conversation, press the button.",
		match_until: "Let us know by 23:59 Sunday",
		match_start: "Yes, I'm in",
		match_pause: "Pause matching",
		match_next_in: "Your next match is in",
		match_notify: "We'll notify you by email.",
		match_arrange: "Arrange a meeting",
		match_feedback: "Share feedback",
		match_email_hint_1: "If you can't find our email, check your spam folder.",
		match_email_hint_2: "And please mark ",
		match_email_hint_3: " as ‘not spam'",
		match_rate_title: "Rate profiles' relevance to get better matches!",
		match_rate_description_1: "Each profile you rate helps our algorithm become more accurate.",
		match_rate_description_2: "Simply choose how relevant each professional is to your goals.",
		match_rate_not_relevant: "Not relevant",
		match_rate_relevant: "Highly relevant",
		match_select_title: "Pick the most relevant profile",
		match_select_description_1: "Take a quick 2-minute test to help us understand your goals.",
		match_select_description_2: "We'll connect you with the ideal expert for your unique needs.",
		match_about: "About",
		match_requests: "Requests",
		match_projects: "Projects",
		feed: "Feed",
		feed_filter: "Filter requests",
		feed_filter_by_industry: "Filter by industry",
		feed_similar: "Similar to you",
		feed_similar_to: "Similar to",
		feed_end_1: "You've scrolled to the end of your feed.",
		feed_end_2: "Please come back later.",
		chat: "Chat",
		chat_empty: "No chats yet",
		chat_empty_description_1: "It seems you don't have any chats right now.",
		chat_empty_description_2: "To start a conversation, wait for a match or help someone out!",
		go_to_feed: "Go to feed",
		go_to_responses: "Go to Responses to me",
		profile: "Profile",
		profile_edit: "Edit profile",
		profile_add_info_title: "Improve Your Experience",
		profile_add_info_hint: "By adding an avatar and more details about yourself, you will attract more attention from potential business partners!",
		profile_add_info_btn: "Add information",
		profile_settings: "Account settings",
		profile_tell_about: "Tell about yourself",
		profile_add_about: "Add about",
		profile_add_project: "Add your project",
		profile_add_request: "Add request",
		profile_add_request_hint: "Post at least one request to receive tailored matches from",
		profile_add_project_description: "Projects help other people understand who you are.",
		profile_add_a_project: "Add a project",
		projects: "Projects",
		projects_add: "Add new project",
		projects_update: "Update project",
		projects_url: "URL",
		projects_url_ph: "Link to your project website",
		projects_title: "Title",
		projects_title_ph: "Enter the title of the project",
		projects_description: "Description",
		projects_description_ph: "Tell about your project",
		projects_add_btn: "Add project",
		projects_update_btn: "Update project",
		confirm_header: "This action can't be undone",
		confirm_exit_text: "Exit without saving changes?",
		confirm_delete_project: "Do you want to delete the project?",
		confirm_delete_request: "Do you want to delete the request?",
		confirm_delete_request_from_feed: "Do you want to delete the request from feed?",
		delete_message: "Delete message",
		confirm_delete_message: "Do you want to delete the message?",
		exit: "Exit",
		cancel: "Cancel",
		ok: "Ok",
		request_type_0: "Share experience",
		request_type_1: "Find a job",
		request_type_2: "Hire",
		request_type_3: "Find clients",
		request_type_4: "Raise funds",
		request_type_5: "Invest",
		request_type_6: "Find experts",
		request_type_7: "Find partners",
		request_type_8: "Network",
		describe_situation: "Describe your situation",
		create_request: "Create request",
		edit_request: "Edit request",
		go_back: "Go back",
		log_in: "Log in",
		my_requests: "My requests",
		waitlist_join: "Join the waitlist",
		admin_page: "Admin page",
		blog: "Blog",
		profile_create_my_request: "Create my request",
		networking_done: "Networking done right",
		landing_text_1: "If we focus more on doing cool stuff with interesting people and less on meeting interesting people, the latter tends to take care of itself. Now post your request, and let us play matchmaker for you.",
		get_started: "Get started",
		landing_dive_in: "Dive in",
		privacy: "Privacy policy",
		profile_post_my_request: "Post my request",
		add: "Add",
		edit_upload_photo: "Upload your photo",
		create_profile: "Create profile",
		edit_describe_request: "Describe your request",
		onboarding_create_first_request: "Create my first request",
		learn_more: "Learn more",
		edit_required_filed: "This is a required field",
		onboarding_other_users_requests: "Check out queries from other professionals",
		onboarding_get_inspired: "Get inspired and create your first request",
		onboarding_choose_tag: "Choose one tag that best describes your expectations for the meeting",
		invites_under_constructing: "Invites are currently under construction",
		close: "Close",
		continue: "Continue",
		requests_similar: "Similar requests",
		give_one_more_chance: "Give us one more chance",
		link_copied: "Link successfully copied to clipboard",
		user_agreement: "User agreement",
		invites_text_1: "Create a community by sharing invites with friends",
		invites: "Invites",
		your_invites: "Your invites",
		scan_qr: "Let your friend scan this QR code",
		requests_pending: "Pending requests",
		requests_accepted: "Accepted requests",
		marketing_emails: "Marketing emails",
		marketing_unsub: "Wanna take a break and unsubscribe from all marketing emails?",
		available: "Available",
		pending: "Pending",
		accepted: "Accepted",
		send_link: "Send link",
		create_qr: "Create QR code",
		revoke_link: "Revoke the link",
		follow: "Follow",
		later: "Later",
		marketing_emails_and_announcements: "Marketing emails and announcements",
		save: "Save",
		unsub: "Unsubscribe",
		thanks: "Thanks",
		delete: "Delete",
		email_newsletters: "Email newsletters",
		back: "Back",
		done: "Done",
		project_preview: "Preview",
		project_upload_preview: "Upload preview",
		project_delete: "Delete Project",
		add_picture: "Add picture",
		apply: "Apply",
		add_industry: "Add industry",
		request_delete: "Delete request",
		no: "No",
		yes: "Yes",
		go_to_chat: "Go to chat",
		full_profile: "Full profile",
		go_to_request: "Go to request",
		first_connection: "First Connection",
		check_inbox: "Please check your inbox.",
		notifications: "Notifications",
		notifications_all: "All notifications",
		write_message: "Write a message",
		send: "Send",
		sign_in_send: "Sign in and send",
		invite_write_message: "Write a message for your invite",
		invite_send: "Send invite",
		stay_signed_in: "Stay signed in",
		suggest_friend: "Suggest a friend",
		suggest: "Suggest",
		accept: "Accept",
		review: "Review",
		hope_back: "We hope we welcome you back!",
		activate_subscription: "Activate membership",
		back_to_profile: "Return to my profile",
		go_to_profile: "Go to profile",
		welcome_osmos: "Welcome to Osmos!",
		got_it: "Got it",
		become_member: "Become a member",
		skip_and_to_service: "Skip and view Osmos",
		profile_edit_profile: "Edit your profile",
		edit_request_choose_color: "Choose a color for your request card",
		i_can_help: "I can help",
		contact_me: "Contact me",
		help_offer: "Help offer",
		accept_and_answer: "Accept and answer",
		reject: "Reject",
		people: "People",
		matches: "Matches",
		required_field: "This is the required field",
		edit_profile_style: "Profile style",
		suggested_requests: "Suggested requests",
		add_one_project: "Add at least one project that you have completed",
		post_a_new_request: "Post a new request",
		feed_sort_feed: "Sort your feed",
		feed_new_first: "New ones first",
		feed_old_first: "Old ones first",
		followers: "Followers",
		following: "Following",
		invite_contacts: "Invite my contacts",
		browse_contacts: "Browse my contacts",
		copy_invite_link: "Copy the invite link",
		gen_invite_link: "Generate an invite link",
		add_friends_to_feed: "Add your friends to feed",
		add_to_feed: "Add to feed",
		control_invites: "Control your invites",
		activate_sub: "Activate subscription",
		explore: "Explore",
		post_one_request_hint: "Post at least one request to receive tailored matches from Osmos",
		post_a_request: "Post a request",
		interesting_people: "People that may be interesting for you",
		share_invite: "Share the invite link",
		invite_reasons_1: "3 reasons",
		invite_reasons_2: "to bring your friends to Osmos:",
		invite_reason_1: "Osmos feed includes only requests and offers from people you are interested in.",
		invite_reason_btn_1: "Ready to start",
		invite_reason_2: "Never miss an opportunity shared by them.",
		invite_reason_btn_2: "Good idea",
		invite_reason_btn_3: "Finally",
		edit: "Edit",
		sign_in_to_help: "Sign in to help",
		feed_more_like_this: "More like this",
		feed_delete_from_feed: "Delete from my feed",
		request_select_background: "Select a cover color and describe your request",
		request_describe_request: "Describe your request accurately",
		describe_expectations: "Describe your expectations from",
		server_error: "Internal Server Error",
		wanna_unsub: "Wanna take a break and unsubscribe from all marketing emails?",
		sure_delete_project: "Are your sure you want to delete your project?",
		request_industry: "Request industry",
		request_describe_request_hint_1: "What kind of person would you like to meet for your business?",
		request_describe_request_hint_2: "The more specific the request, the better your matches will be.",
		congrats_begin: "Congratulations on breaking the ice!",
		we_will_notify_about_answer: "We'll email you when you receive a response.",
		email_sent: "We've sent you an email.",
		expand_network: "Expand your network",
		check_request: "Check request",
		search_by_email: "Search by email",
		verify_new_email: "Verify your new email",
		check_inbox_verify_email: "Please check your inbox to verify your new email.",
		search_by_name: "Search by name",
		welcome_message_1: "We'll show you around so you can",
		welcome_message_2: "easily connect with the right people.",
		matched_people: "People that match your request",
		only_one_message: "You can only send one message. Please wait until the person accepts the connection.",
		all_chats: "All chats",
		request_published: "Your request has been published!",
		request_published_subtitle_1: "You can find it in your profile",
		request_published_subtitle_2: "or continue exploring the feed.",
		stay_feed: "Stay in feed",	
		add_request_intro_short_1: "Oops, your request is a bit brief! Mind adding more details?",		
		add_request_intro_short_2: "Heading in the right direction! Yet, we could benefit from more details.",		
		add_request_intro_short_3: "Great request, kudos!",
		request_placeholder_0: "I would like to meet people who have experience in this and that...",
		request_placeholder_1: "I am looking for a position in…",
		request_placeholder_2: "I am looking for professionals with experience in…",
		request_placeholder_3: "I am looking for people or businesses interested in...",
		request_placeholder_4: "I am looking for investors interested in... at this stage...",
		request_placeholder_5: "I am looking for teams working in... at this stage...",
		request_placeholder_6: "I am looking for professionals to help me and my business with...",
		request_placeholder_7: "I'm currently on the lookout for companies and individuals who are also interested in collaborating in specific field...",
		request_placeholder_8: "I would like to meet people who are...",
		request_placeholder_9: "I am interested in meeting people who do...",
		init_message_default: "Hi! I can help you with...",
		init_message_can_help: "I hope you're doing well! I'm looking for …",
		init_message_hi: "Hi",
		init_message_start_chat: "I would be happy to meet up. Please let me know if that works for you, or feel free to use my Calendly link...",
		unsub_message: "Thank you for staying with us! Now you are unsubscribed from all newsletters",
		notifications_type_0: "is ready to help you",
		notifications_type_1: "is your new match",
		notifications_type_2: "sent you a new message",
		notifications_type_3: "started following you",
		notifications_type_4: "posted a new request",
		chat_select_offer: "Select an offer to answer", 
		chat_select_offer_edit: "Select an offer to edit", 
		chat_select_chat: "Select a chat to start messaging",
		rate_match_question: "Was this match relevant to your\u00A0request?",
		rate_match_question_2: `Did you learn something new?`,
		event_popup_new_match: "New match",
		event_popup_suggested_you: "suggested you for",
		event_popup_suggested: "suggested",
		event_popup_new_event: "New Event",
		rate_user_not_helped: "Not really", 
		rate_user_helped: "Yes, definitely!",
		hint_description_title: "These are the requests we've curated for you based on your professional background and bio. Discover who could benefit greatly from your expertise.",
		hint_description_1: "Notify the person of your ability to aid them and share further details through a private message.",
		hint_description_2: "Do you have connections who could assist? Share the request and simplify the intro process.",
    },
    [LOCALES.RUSSIAN]: {
		reg_create_account: "Создать аккаунт",
		reg_continue_with: "Продолжить через",
		reg_continue: "Продолжить",
		reg_new_in: "Новичок в Osmos?",
		reg_join_now: "Войти",
		reg_continue_with_email: "Продолжить через почту",
		reg_your_email: "Ваш e-mail",
		reg_send_link: "Отправить волшебную ссылку",
		reg_check_email: "Загляните в почтовый ящик. Мы отправили вам волшебную ссылку для авторизации",
		reg_open_mailbox: "В почту",
		reg_ok_got_it: "Отлично!",
		reg_auth_timeout: "Время для авторизации истекло",
		reg_send_again: "Отправить ещё раз",
		reg_error: "Что-то пошло не так",
		reg_try_again: "Попробуйте позже",
		reg_reload_page: "Перезагрузить",
		sign_up: "Зарегистрироваться",
		sign_in: "Войти",
		fname: "Имя",
		fname_ph: "Ваше имя",
		lname: "Фамилия",
		lname_ph: "Ваша фамилия",
		next: "Далее",
		skip: "Пропустить",
		message: "Сообщение",
		or: "или",
		save_draft: "Сохранить",
		settings: "Настройки",
		settings_support: "Помощь",
		settings_support_email: "Напишите нам, если у вас возникли вопросы",
		settings_profile_settings: "Настройки аккаунта",
		settings_profile_email_prefs: "Оповещения",
		settings_profile_email_pref_1: "Сообщать о новых собеседниках по почте",
		settings_profile_email_pref_2: "Присылать новости проекта",
		settings_profile_email_pref_3: "Оповещать об обновлениях в сервисе",
		settings_profile_save: "Сохранить изменения",
		settings_profile_email_unsub: "Отписаться от всех маркетинговых рассылок",
		settings_profile_delete: "Удалить аккаунт",
		settings_profile_delete_reason_0: "У меня есть второй аккаунт",
		settings_profile_delete_reason_1: "Я получаю слишком много писем",
		settings_profile_delete_reason_2: "Я не получают результата от подписки на сервис",
		settings_profile_delete_reason_3: "У меня есть опасения по поводу конфиденциальности моих данных",
		settings_profile_delete_reason_4: "Меня не устраивают собеседники, которых я получаю",
		settings_profile_delete_reason_5: "Другое",
		settings_profile_delete_hint_1: "Ваша подписка ещё активна. Активный период заканчивается",
		settings_profile_delete_hint_2: "Вы можете отменить автоматическое продление подписки в любой момент до даты очередного списания, тем не менее, средства за активный период подписки возвращены не будут.",
		settings_profile_delete_final_1: "Спасибо за обратную связь",
		settings_profile_delete_final_2: "мы постоянно становимся лучше",
		settings_profile_delete_final_3: "Вы потеряете доступ к аккаунту после его удаления. Ваша подписка будет отменена, и с вас не будет взиматься плата за следующий период. Однако мы не предоставляем возврат средств за оставшийся период подписки после удаления учетной записи.",
		settings_logout: "Выйти",
		settings_logout_stay: "Остаться",
		settings_logout_hint: "Выйдя из системы, вы выйдете из текущего сеанса.",
		read_more: "Больше",
		nothing_found: "Ничего не найдено",
		ob_onlylatin: "To broaden your match possibilities, ensure you only use the English language",
		ob_header_common: "Давайте знакомиться!",
		ob_notify_matches: "Сообщать о новых собеседниках по почте",
		ob_concent: "Даю согласие на",
		ob_processing_data: "обработку моих персональных данных",
		ob_photo_header: "Добавьте фото",
		ob_photo_hint: "Людям проще начать разговор с теми, кого они видят",
		ob_add_from_lib: "Загрузить",
		ob_intro_header: "Опишите себя так, чтобы другим профессионалам было интересно с вами связаться.",
		ob_intro_hint: "Над чем вы сейчас работаете? Что вы знаете или умеете лучше всех? Какую помощь вы можете предложить другим?",
		ob_intro_short_1: "Коротковато для биографии! Добавите больше деталей?",
		ob_intro_short_2: "Движетесь в нужном направлении! Добавьте еще деталей, и будет идеально.",
		ob_intro_short_ok: "Выглядит отлично! Спасибо, что поделились!",
		ob_industry_header: "Выберите свою индустрию",
		industry_ph: "Начните ввод",
		industry_ph_40: "Начните ввод",
        industry: "Индустрия",
		ob_occupation_header: "Добавьте в профиль больше деталей",
		ob_occupation_company: "Компания",
		occupation_company_ph: "Введите название вашей компании",
		ob_occupation_role: "Позиция",
		occupation_role_ph: "Кем вы работаете?",
		ob_occupation_city: "Город",
		city_ph: "Введите название города, в котором живете",
		match: "Собеседники",
		match_are_you_ready: "Если вы готовы к новому знакомству и беседе\u00A01:1, нажмите кнопку.",
		match_until: "Сообщите нам до 23:59 воскресенья",
		match_start: "Я в деле!",
		match_pause: "Остановить",
		match_next_in: "Ваш следующий собеседник появится",
		match_notify: "Мы отправим уведомление на почту.",
		match_arrange: "К встрече",
		match_feedback: "Оставить отзыв",
		match_email_hint_1: "Если вы не нашли письма, проверьте папку со спамом.",
		match_email_hint_2: "Пожалуйста, отметьте ",
		match_email_hint_3: " как ‘не спам'",
		match_rate_title: "Оцените эти профили чтобы получать подходящих собеседников!",
		match_rate_description_1: "Каждый оценённый вами профиль помогает нашим алгоритмам работать лучше.",
		match_rate_description_2: "Просто укажите, насколько эти профессионалы подходят для ваших целей.",
		match_rate_not_relevant: "Абсолютно не соответствует",
		match_rate_relevant: "Полностью отвечает запросу",
		match_select_title: "Выберите более подходящий профиль",
		match_select_description_1: "Пройдите быстрый опрос, чтобы мы лучше поняли ваши цели.",
		match_select_description_2: "Мы соединим вас с экспертом, который идеален для вас.",
		match_about: "Описание",
		match_requests: "Запросы",
		match_projects: "Проекты",
		feed: "Лента",
		feed_filter: "Фильтр",
		feed_filter_by_industry: "Выберите интересующие теги",
		feed_similar: "Для вас",
		feed_similar_to: "Похожие профили",
		feed_end_1: "Вы долистали до конца ленты.",
		feed_end_2: "Пожалуйста, вернитесь позднее.",
		chat: "Чат",
		chat_empty: "Чатов пока нет",
		chat_empty_description_1: "Кажется, у вас пока что нет ни одного чата.",
		chat_empty_description_2: "Чтобы начать общение, дождитесь, пока вас соединят с собеседником, или ответьте на интересующий вас запрос в ленте!",
		go_to_feed: "К ленте",
		go_to_responses: "К ответам на запросы",
		profile: "Профиль",
		profile_edit: "Редактировать профиль",
		profile_add_info_title: "Получите больше внимания",
		profile_add_info_hint: "Если вы добавите аватар и детали о себе, ваш профиль будет выглядеть более убедительно!",
		profile_add_info_btn: "Добавить информацию",
		profile_settings: "Настройки аккаунта",
		profile_add_about: "Добавь описание",
		profile_tell_about: "Расскажи о себе",
		profile_add_project: "Добавить проект",
		profile_add_request: "Добавить запрос",
		profile_add_request_hint: "Опубликуйте хотя бы один запрос, чтобы получить собеседника от",
		profile_add_project_description: "Проекты помогают другим людям понять, кто вы",
		profile_add_a_project: "Добавить проект",
		projects: "Проекты",
		projects_add: "Добавить новый проект",
		projects_update: "Редактировать проект",
		projects_url: "URL",
		projects_url_ph: "Ссылка на сайт вашего проекта",
		projects_title: "Название",
		projects_title_ph: "Введите название проекта",
		projects_description: "Описание",
		projects_description_ph: "Расскажите о проекте подробнее",
		projects_add_btn: "Добавить проект",
		projects_update_btn: "Обновить проект",
		confirm_header: "Это действие нельзя отменить",
		confirm_exit_text: "Выйти, не сохранив изменения?",
		confirm_delete_project: "Вы действительно хотите удалить проект?",
		confirm_delete_request: "Вы действительно хотите удалить запрос?",
		confirm_delete_request_from_feed: "Вы действительно хотите удалить запрос из ленты?",
		delete_message: "Удалить сообщение",
		confirm_delete_message: "Вы действительно хотите удалить сообщение?",
		exit: "Выйти",
		ok: "Ок",
		cancel: "Отмена",
		request_type_0: "Обмен опытом",
		request_type_1: "Поиск работы",
		request_type_2: "Поиск сотрудников",
		request_type_3: "Поиск клиентов",
		request_type_4: "Поиск инвестиций",
		request_type_5: "Инвестирование",
		request_type_6: "Поиск экспертов",
		request_type_7: "Поиск партнёров",
		request_type_8: "Нетворкинг",
		describe_situation: "Опишите свою ситуацию",
		create_request: "Создать запрос",
		edit_request: "Обновить запрос",
		go_back: "Назад",
		log_in: "Авторизоваться",
		my_requests: "Мои запросы",
		waitlist_join: "Присоединиться к списку ожидания",
		admin_page: "Админка",
		blog: "Блог",
		profile_create_my_request: "Создать запрос",
		networking_done: "Все по плану!",
		landing_text_1: "Если сосредоточиться на том, чтобы делать крутые вещи с интересными людьми, вместо того, чтобы просто встречаться с интересными людьми, встречи, как правило, начинают случаться сами собой. Опишите, чем вы занимаетесь и что ищете, и мы сами сведем вас с наиболее подходящими собеседниками.",
		get_started: "Начать",
		landing_dive_in: "Углубиться",
		privacy: "Политика конфиденциальности",
		profile_post_my_request: "Опубликовать запрос",
		add: "Добавить",
		edit_upload_photo: "Загрузить фото",
		create_profile: "Создать профиль",
		edit_describe_request: "Опишите запрос",
		onboarding_create_first_request: "Создать первый запрос",
		learn_more: "Узнать больше",
		edit_required_filed: "Это поле необходимо к заполнению",
		onboarding_other_users_requests: "Проверить запросы других специалистов",
		onboarding_get_inspired: "Вдохновитесь и создайте свой первый запрос",
		onboarding_choose_tag: "Выберите тэг, который лучше всего описывает ваши ожидания от встречи",
		invites_under_constructing: "Приглашения сейчас в разработке",
		close: "Закрыть",
		continue: "Продолжить",
		requests_similar: "Похожие запросы",
		give_one_more_chance: "Дайте нам еще один шанс",
		link_copied: "Линк успешно скопирован",
		user_agreement: "Пользовательское соглашение",
		invites_text_1: "Создайте сообщество, отправив друзьям приглашение",
		invites: "Приглашения",
		your_invites: "Ваши приглашения",
		scan_qr: "Ваши друзья должны просканировать QR код",
		requests_pending: "Ожидающие запросы",
		requests_accepted: "Принятые запросы",
		marketing_emails: "Маркетинговые письма",
		marketing_unsub: "Хотите сделать перерыв и отписаться от всех маркетинговых писем?",
		available: "Доступно",
		pending: "Ожидание",
		accepted: "Принято",
		send_link: "Отправить ссылку",
		create_qr: "Создать QR-код",
		revoke_link: "Отозвать ссылку",
		follow: "Подписаться",
		later: "Позже",
		marketing_emails_and_announcements: "Маркетинговые письма и объявления",
		save: "Сохранить",
		unsub: "Отписаться",
		thanks: "Спасибо",
		delete: "Удалить",
		email_newsletters: "Отправить рассылки",
		back: "Назад",
		done: "Готово",
		project_preview: "Изображение",
		project_upload_preview: "Загрузить изображение",
		project_delete: "Удалить проект",
		add_picture: "Добавить изображение",
		apply: "Применить",
		add_industry: "Добавить отрасль",
		request_delete: "Удалить запрос",
		no: "Нет",
		yes: "Да",
		go_to_chat: "Перейти в чат",
		full_profile: "Полный профиль",
		go_to_request: "Перейти к запросу",
		first_connection: "Первое подключение",
		check_inbox: "Проверьте свой почтовый ящик",
		notifications: "Уведомления",
		notifications_all: "Все уведомления",
		write_message: "Написать сообщение",
		send: "Отправить",
		sign_in_send: "Войти и отправить",
		invite_write_message: "Написать сообщение для приглашения",
		invite_send: "Отправить приглашение",
		stay_signed_in: "Оставаться в системе",
		suggest_friend: "Предложить друга",
		suggest: "Предложить",
		accept: "Принять",
		review: "Обзор",
		hope_back: "Надеемся снова вас увидеть!",
		activate_subscription: "Активировать подписку",
		back_to_profile: "Вернуться в мой профиль",
		go_to_profile: "Перейти в профиль",
		welcome_osmos: "Добро пожаловать в Osmos!",
		got_it: "Понятно",
		become_member: "Стать участником",
		skip_and_to_service: "Пропустить и просмотреть Osmos",
		profile_edit_profile: "Изменить свой профиль",
		edit_request_choose_color: "Выбрать цвет для карточки запроса",
		i_can_help: "Хочу пообщаться",
		contact_me: "Связаться со мной",
		help_offer: "Предложить помощь",
		accept_and_answer: "Принять и ответить",
		reject: "Отклонить",
		people: "Люди",
		matches: "Собеседники",
		required_field: "Это обязательное поле",
		edit_profile_style: "Стиль профиля",
		suggested_requests: "Предложенные запросы",
		add_one_project: "Добавьте хотя бы один проект, который вы завершили",
		post_a_new_request: "Опубликовать новый запрос",
		feed_sort_feed: "Сортировать ленту",
		feed_new_first: "Сначала новые",
		feed_old_first: "Сначала старые",
		followers: "Подписчики",
		following: "Подписки",
		invite_contacts: "Пригласить мои контакты",
		browse_contacts: "Просмотреть мои контакты",
		copy_invite_link: "Скопировать пригласительную ссылку",
		gen_invite_link: "Создать пригласительную ссылку",
		add_friends_to_feed: "Добавить друзей в ленту",
		add_to_feed: "Добавить в ленту",
		control_invites: "Управлять приглашениями",
		activate_sub: "Активировать подписку",
		explore: "Исследовать",
		post_one_request_hint: "Опубликуйте хотя бы один запрос, чтобы получать персонализированные совпадения от Osmos",
		post_a_request: "Опубликовать запрос",
		interesting_people: "Люди, которые могут быть вам интересны",
		share_invite: "Поделитесь ссылкой приглашения",
		invite_reasons_1: "3 причины",
		invite_reasons_2: "привести друзей в Osmos:",
		invite_reason_1: "Лента Osmos содержит запросы и предложения только от тех людей, которые вам интересны.",
		invite_reason_btn_1: "Готовы начать",
		invite_reason_2: "Никогда не упускайте возможности, которыми они поделились.",
		invite_reason_btn_2: "Хорошая идея",
		invite_reason_btn_3: "Наконец-то",
		edit: "Изменить",
		sign_in_to_help: "Войдите, чтобы помочь",
		feed_more_like_this: "Еще похожее",
		feed_delete_from_feed: "Удалить из моей ленты",
		request_select_background: "Выберите цвет обложки и опишите свой запрос",
		request_describe_request: "Опишите свой точный запрос",
		describe_expectations: "Укажите цель знакомства",
		server_error: "Внутренняя ошибка сервера",
		wanna_unsub: "Хотите сделать перерыв и отписаться от всех маркетинговых писем?",
		sure_delete_project: "Вы уверены, что хотите удалить свой проект?",
		request_industry: "Отрасль запроса",
		request_describe_request_hint_1: "С каким человеком вы хотели бы встретиться?",
		request_describe_request_hint_2: "Чем конкретнее запрос, тем лучше будут подобраны собеседники.",
		congrats_begin: "Поздравляем с началом!",
		we_will_notify_about_answer: "Мы отправим вам email, когда вы получите ответ.",
		email_sent: "Мы отправили вам электронное письмо.",
		expand_network: "Расширьте свою сеть",
		check_request: "Проверьте запрос",
		search_by_email: "Поиск по email",
		verify_new_email: "Подтвердите новый email-адрес",
		check_inbox_verify_email: "Пожалуйста, проверьте почту, чтобы подтвердить новый email-адрес",
		search_by_name: "Поиск по имени",
		welcome_message_1: "Мы все вам покажем, чтобы вы могли",
		welcome_message_2: "легко связаться с нужными людьми.",
		matched_people: "Люди, соответствующие вашему запросу",
		only_one_message: "Вы можете отправить только одно сообщение. Пожалуйста, подождите, пока человек примет диалог.",
		all_chats: "Все чаты",
		request_published: "Ваш запрос опубликован!",
		request_published_subtitle_1: "Вы можете найти запрос в вашем профиле",
		request_published_subtitle_2: "или остаться просматривать ленту.",
		stay_feed: "Остаться в ленте",
		add_request_intro_short_1: "Ой, ваш запрос слишком короткий. Добавьте больше деталей!",		
		add_request_intro_short_2: "Вы на правильном пути! Чуть больше деталей не помешает.",		
		add_request_intro_short_3: "Отличный запрос!",
		request_placeholder_0: "Я хотел(а) бы встретить людей, которые имеют опыт в этом и том...",
		request_placeholder_1: "Я ищу позицию в…",
		request_placeholder_2: "Я ищу профессионалов с опытом в…",
		request_placeholder_3: "Я ищу людей или компании, заинтересованные в...",
		request_placeholder_4: "Я ищу инвесторов, заинтересованных в... на этом этапе...",
		request_placeholder_5: "Я ищу команды, работающие в... на этом этапе...",
		request_placeholder_6: "Я ищу профессионалов, которые могут помочь мне и моему бизнесу с...",
		request_placeholder_7: "В данный момент я ищу компании и частных лиц, которые также заинтересованы в сотрудничестве в конкретной области...",
		request_placeholder_8: "Я хотел(а) бы встретиться с людьми, которые являются...",
		request_placeholder_9: "Мне интересно встретиться с людьми, которые занимаются...",
		init_message_default: "Добрый день! Я могу помочь…",
		init_message_default_2: "Приятно познакомиться! Я занимаюсь …",
		init_message_hi: "Привет",
		init_message_start_chat: "Буду рад(а) пообщаться лично или на звонке! Когда вам удобно?",
		unsub_message: "Спасибо за использование сервиса! Теперь вы отписаны от всех маркетинговых рассылок",
		notifications_type_0: "отвечает на ваш запрос",
		notifications_type_1: "ваш новый собеседник",
		notifications_type_2: "отправил(а) вам сообщение",
		notifications_type_3: "теперь подписан(а) на вас",
		notifications_type_4: "опубликовал(а) новый запрос",
		chat_select_offer: "Выберите чат", 
		chat_select_offer_edit: "Выберите чат для редактирования", 
		chat_select_chat: "Выберите чат, чтобы начать общение",
		rate_match_question: "Насколько этот собеседник соответствует вашему запросу?",
		rate_match_question_2: `Узнали ли вы что-то новое?`,
		event_popup_new_match: "Новый диалог",
		event_popup_suggested_you: "рекомендовал(а) вас для",
		event_popup_suggested: "рекомендует",
		event_popup_new_event: "Новое событие",
		rate_user_not_helped: "Не особо", 
		rate_user_helped: "Однозначно да",
		hint_description_title: "Посмотрите на запросы, которые мы подобрали на основании описания вашего профиля. Возможно, кому-то поможет ваш профессиональный совет!",
		hint_description_1: "Отправьте пользователю уведомление о том, что вы можете помочь с его запросом.",
		hint_description_2: "Знаете, кто еще может помочь? Поделитесь запросом с коллегой, чья экспертиза кажется вам полезной.",
	}
}