import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { 
    DateFromString, 
    DateToTimestamp, 
    GetWebsocketUrl, 
    LogOut, 
    MakeValidURL, 
    OtherDayTimeFromString, 
    SetDict, 
    TimeFromString, 
    URLRegExp 
} from "../elements/Data";
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { 
    Edit, 
    NewAvatar 
} from "../elements/Elements.jsx";
import { 
    ConfirmPopup, 
} from "../elements/Popups";
import { 
    AngleRightBlack,
    OkGray,
    OkGreen,
    PlusWhite,
    SendMsgBlack,
    TrashCoral,
} from "../elements/Imgs";
import { MessagesRequest, ProfileRequest, RefreshId } from "../Requests";
import posthog from "posthog-js";
import Smartlook from 'smartlook-client';
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { FeedRequestCardMobile } from "./RequestCards";
import { FormattedMessage } from "react-intl";

import { messages as translate_messages } from "../../i18n/messages";

const ChatListMobile= (props: any) => {
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);

    return (
    <div className={`w-flex px-3 pb-3`}>
        {(
            props.chats?.length ||
            props.chatsRequested?.length ||
            props.chatsOffers?.length
        ) ? 
        <div className={`block px-2 b-3 w-flex mb-3`}>
            {[
                ...(props.chats ?? []), 
                ...(props.chatsRequested ?? []).map((elem: any, index: number) => SetDict(elem, ["requested_index"], index)),
                ...(props.chatsOffers ?? []).map((elem: any, index: number) => SetDict(elem, ["offer_index"], index)),
            ].sort((
                a: {last_message?: {created_at: string | number}, created_at?: string | number}, 
                b: {last_message?: {created_at: string | number}, created_at?: string | number},
            ) => DateToTimestamp(b.last_message?.created_at ?? b?.created_at ?? 0) - DateToTimestamp(a.last_message?.created_at ?? a?.created_at ?? 0))
            .map((elem: any, index: number) => {return (typeof elem?.requested_index === 'number' ? 
            <Fragment key={index}>
                {!!index && 
                <div style={{
                    height: 1,
                }} className={`w-flex block mb`} data-color={`light-gray`}></div>}
                <div className={`w-flex row left top nowrap noscroll py-2`} onClick={() => {
                        props.setOfferPopup(elem);
                    }}> 
                    <NewAvatar size={4} type={elem.pair?.avatar?.style ?? 0} name={elem.pair?.name}
                            avatar={elem.pair?.avatar?.link}/>
                    <div className={`w-flex pt`}>
                        <div className={`row nowrap w-flex top mb`}>
                            <div className={`row nowrap`}>
                                <p data-size={6} className={`semibold seminarrow ellipsis`} data-color={`black`}
                                   style={{maxWidth: 120}}>
                                    {elem.pair?.name}
                                </p>  
                                {!elem?.is_new_requested &&
                                <div className={`label block btn-circled-16 mobile`} data-color={`gray`}>
                                    <p className={`regular`}><FormattedMessage id="pending"/></p>
                                </div>}
                            </div>
                            <p data-size={7} className={`noshrink seminarrow`} data-color={'black-40-opacity'}>
                                {DateFromString(elem.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem.created_at) : OtherDayTimeFromString(elem.created_at)}
                            </p>
                        </div>
                        <div className={`row nowrap w-flex bottom`}>
                            <p data-size={7} className={`w-flex`} data-color={`black`} data-lines={2} style={{
                                    overflowWrap: 'break-word',
                                    wordBreak: `break-word`,
                                }}>
                                {(elem?.items[(elem?.items?.length ?? 1) - 1]?.text ?? (elem?.is_new_requested ? 
                                    (elem?.is_can_help ? translate_messages[globalContext.locale]?.init_message_default : 
                                        translate_messages[globalContext.locale]?.init_message_default_2) :
                                    `${translate_messages[globalContext.locale]?.init_message_hi}, ${(elem.pair?.name + ` `).split(' ')[0]}! ${translate_messages[globalContext.locale]?.init_message_start_chat}`))
                                .trim().split('\n').map((elem_: string, index: number) => {return (
                                    <span key={index} data-color={elem?.items[(elem?.items?.length ?? 1) - 1]?.text ? null : `black-40-opacity`}>
                                        {elem_}<br/>
                                    </span>
                                )})}
                            </p>  
                            {!!elem?.unread &&
                            <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`green`}>
                                <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                            </div>}
                        </div>
                    </div>
                </div> 
            </Fragment> :
            (typeof elem?.offer_index === 'number' ? 
            <Fragment key={index}>
                {!!index && 
                <div style={{
                    height: 1,
                }} className={`w-flex block mb`} data-color={`light-gray`}></div>}
                <div className={`w-flex row left top nowrap noscroll py-2`} onClick={() => {
                        props.setOfferPopup(elem);
                    }}> 
                    <NewAvatar size={4} type={elem.user?.avatar?.style ?? 0} name={elem.user?.name}
                            avatar={elem.user?.avatar?.link}/>
                    <div className={`w-flex pt`}>
                        <div className={`row nowrap w-flex top mb`}>
                            <div className={`row nowrap`}>
                                <p data-size={6} className={`semibold seminarrow ellipsis`} data-color={`black`}
                                style={{maxWidth: 120}}>
                                    {elem.user?.name}
                                </p>  
                                <div className={`label block btn-circled-16 mobile`} data-color={`bright-blue`}>
                                    <p className={`regular`}><FormattedMessage id="help_offer"/></p>
                                </div>
                            </div>
                            <p data-size={7} className={`noshrink seminarrow`} data-color={'black-40-opacity'}>
                                {DateFromString(elem.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem.created_at) : OtherDayTimeFromString(elem.created_at)}
                            </p>
                        </div>
                        <div className={`row nowrap w-flex bottom`}>
                            <p data-size={7} className={`w-flex`} data-color={`black`} data-lines={2} style={{
                                    overflowWrap: 'break-word',
                                    wordBreak: `break-word`,
                                }}>
                                {(elem?.items[(elem?.items?.length ?? 1) - 1]?.text ?? (elem?.is_new_requested ? 
                                    (elem?.is_can_help ? translate_messages[globalContext.locale]?.init_message_default : 
                                        translate_messages[globalContext.locale]?.init_message_default_2) :
                                    `${translate_messages[globalContext.locale]?.init_message_hi}, ${(elem.user?.name + ` `).split(' ')[0]}! ${translate_messages[globalContext.locale]?.init_message_start_chat}`))
                                .trim().split('\n').map((elem_: string, index: number) => {return (
                                    <span key={index} data-color={elem?.items[(elem?.items?.length ?? 1) - 1]?.text ? null : `black-40-opacity`}>
                                        {elem_}<br/>
                                    </span>
                                )})}
                            </p>  
                            {!!elem?.unread &&
                            <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`green`}>
                                <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                            </div>}
                        </div>
                    </div>
                </div> 
            </Fragment> :
            <Fragment key={index}>
                {!!index && 
                <div style={{
                    height: 1,
                }} className={`w-flex block mb`} data-color={`light-gray`}></div>}
                <div className={`w-flex row left top nowrap noscroll py-2`} onClick={() => {
                        navigate(`/messenger/${elem?.id}`);
                    }}> 
                    <NewAvatar size={4} type={elem?.avatar?.style ?? 0} name={elem?.name}
                            avatar={elem?.avatar?.link}/>
                    <div className={`w-flex pt`}>
                        <div className={`row nowrap w-flex top mb`}>
                            <p data-size={6} className={`semibold seminarrow ellipsis`} data-color={`black`}
                               style={{maxWidth: 120}}>
                                {elem.name}
                            </p>  
                            <p data-size={7} className={`noshrink seminarrow`} data-color={'black-40-opacity'}>
                                {DateFromString(elem[`last_message`]?.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem[`last_message`]?.created_at) : OtherDayTimeFromString(elem[`last_message`]?.created_at)}
                            </p>
                        </div>
                        <div className={`row nowrap w-flex bottom`}>
                            <p data-size={7} className={`w-flex`} data-color={`black`} data-lines={2} style={{
                                    overflowWrap: 'break-word',
                                    wordBreak: `break-word`,
                                }}>
                                {(elem['last_message']?.content ?? 
                                `${translate_messages[globalContext.locale]?.init_message_hi}, ${(elem?.name + ` `).split(' ')[0]}! ${translate_messages[globalContext.locale]?.init_message_start_chat}`)
                                .trim().split('\n').map((elem_: string, index: number) => {return (
                                    <span key={index} data-color={elem['last_message']?.content ? null : `black-40-opacity`}>
                                        {elem_}<br/>
                                    </span>
                                )})}
                            </p> 
                            {!!elem?.unread &&
                            <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`green`}>
                                <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                            </div>}
                        </div>
                    </div>
                </div> 
            </Fragment>))})}
        </div> : 
        <div className={`skeleton w-flex b-3 mb-3 h-9 mb-3`}></div>}
    </div>)
}

const OfferListMobile= (props: any) => {
    const globalContext = useContext(GlobalContext);

    return (
    <div className={`w-flex px-3 pb-3`}>
        {!!props.offers && props.offers?.length ? 
        <div className={`block p-3 b-3 w-flex mb-3`}>
            {props.offers.map((elem: any, index: number) => {return (<Fragment key={index}>
                {!!index && 
                <div style={{
                    height: 1,
                }} className={`w-flex block mb`} data-color={`light-gray`}></div>}
                <div className={`w-flex row left gap-2 top nowrap noscroll py-2`} onClick={() => {
                        props.setOfferPopup(elem);
                    }}> 
                    <NewAvatar size={4} type={(props.requested ? elem.pair : elem.user)?.avatar?.style ?? 0} name={elem.user?.name}
                            avatar={(props.requested ? elem.pair : elem.user)?.avatar?.link}/>
                    <div className={`w-flex`}>
                        <div className={`row nowrap w-flex top`}>
                            <p className={`semibold seminarrow text-6 mr-2`} data-color={`black`}>
                                {(props.requested ? elem.pair : elem.user)?.name}
                            </p>  
                            <p className={`text-6 noshrink seminarrow`} data-color={'black-40-opacity'}>
                                {DateFromString(elem.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem.created_at) : OtherDayTimeFromString(elem.created_at)}
                            </p>
                        </div>
                        <div className={`row nowrap w-flex bottom`}>
                            <p className={`text-6 w-flex`} data-color={`black`} data-lines={2} style={{
                                    overflowWrap: 'break-word',
                                    wordBreak: `break-word`,
                                }}>
                                {(elem?.items[(elem?.items?.length ?? 1) - 1]?.text ?? 
                                `${translate_messages[globalContext.locale]?.init_message_hi}, ${((props.requested ? elem.pair : elem.user)?.name + ` `).split(' ')[0]}! ${translate_messages[globalContext.locale]?.init_message_start_chat}`)
                                .trim().split('\n').map((elem_: string, index: number) => {return (
                                    <span key={index} data-color={elem?.items[(elem?.items?.length ?? 1) - 1]?.text ? null : `black-40-opacity`}>
                                        {elem_}<br/>
                                    </span>
                                )})}
                            </p>  
                            {!!elem?.unread &&
                            <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`blue`}>
                                <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                            </div>}
                        </div>
                    </div>
                </div> 
            </Fragment>)})}
        </div> :
        <div className={`skeleton w-flex b-3 mb-3 h-9 mb-3`}></div>}
    </div>)
}

const ChatMobile = (props: any) => {
    const navigate = useNavigate();
    const [ cookie ] = useCookies(['user_id_cookie']);
    const { id } = useParams();
    const context = useContext(ProfileContext);

    const globalContext = useContext(GlobalContext);
    
    interface Message {
        id: string,
        from_user: string,
        is_read: boolean,
        content: string,
        created_at: string | number,
        reply_to: {
            text: string,
            created_at: number | string,
            user_name?: string,
            user_occupation?: string,
            user_avatar: {
                link?: string,
                style?: number,
            },
        }
    }

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ wsEstablished, setWsEstablished ] = useState<boolean>(false);
    const [ firstLoading, setFirstLoading ] = useState<boolean>(true);

    const [ ws, setWs ] = useState<WebSocket | null>(null);
    const [ receivedMessage, setReceivedMessage ] = useState<any>({});
    const [ messages, setMessages ] = useState<Message[]>([]);
    const [ newMessage, setNewMessage ] = useState<string>('');    

    const [ interlocutor, setInterlocutor ] = useState<any>({});
    const [ profile, setProfile ] = useState<any>({});

    const [ controlMessage, setControlMessage ] = useState<any>(null);
    const [ editMessageId, setEditMessageId ] = useState<string | null>(null);
    const [ deleteMessageId, setDeleteMessageId ] = useState<string | null>(null);

    const [ showSendBtn, setShowSendBtn ] = useState<boolean>(false);

    const chatInput = useRef(null);

    const onSubmit = (e: any) => {
        e.preventDefault();        
        if (profile?.subscription?.is_subscription_active === false) {

        } else if (newMessage.trim() && ws?.readyState === 1) {
            setControlMessage(null);
            if (editMessageId) {
                ws.send(JSON.stringify({
                    'event_type': 'edit',
                    'message_id': editMessageId,
                    'edited_message': newMessage.trim(),
                }));
                setEditMessageId(null);
            } else {
                ws.send(JSON.stringify({
                    'event_type': 'send',
                    'message': newMessage.trim(),
                    'refer_to': context.newChatOffer?.refer_to_request?.id ?? null,
                }));
            }
            setNewMessage('');
            // context.setNewChatOffer(null);
        }
    }

    useEffect(() => {
        if (wsEstablished) {
            const unreadMessages: Message[] = messages.filter(val => !val.is_read && val.from_user !== cookie['user_id_cookie']) as Message[];
            if (ws?.readyState === 1 && unreadMessages.length) {             
                ws.send(JSON.stringify({
                    'event_type': 'read',
                    'message_id': unreadMessages[unreadMessages.length - 1].id,
                }));
            }
        }
    }, [wsEstablished, messages[(messages?.length ?? 1) - 1]])

    useEffect(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: firstLoading ? 'instant' as ScrollBehavior : 'smooth' as ScrollBehavior,
        });
        if (messages?.length) {
            setFirstLoading(false);
        }      
    }, [messages[(messages?.length ?? 1) - 1]]);

    useEffect(() => {
        document.documentElement.style.overflow = controlMessage ? `hidden` : 'unset'; 
    }, [controlMessage])

    useEffect(() => {
        setNewMessage(messages.find(val => val.id === editMessageId)?.content ?? '');        
        if (chatInput.current && editMessageId) {
            (chatInput.current as HTMLTextAreaElement).focus();
        }
    }, [editMessageId])

    useEffect(() => {
        if (messages && interlocutor && profile) {
            setLoaded(true);
        }      
        if (messages && !messages?.length && interlocutor?.fname) {
            setNewMessage(
                `${translate_messages[globalContext.locale]?.init_message_hi}, ${interlocutor?.fname}! ${translate_messages[globalContext.locale]?.init_message_start_chat}`
            )
        }
    }, [interlocutor, profile, messages])

    useEffect(() => {
        MessagesRequest(id, setMessages, setInterlocutor, {
            404: () => { navigate("/404") },
        });
        ProfileRequest(cookie['user_id_cookie'], setProfile);

        RefreshId(() => {}, () => {
            LogOut(encodeURIComponent(window.location.pathname ?? `/messenger/${id}`)) 
        })

        return () => {
            if (ws) {
                ws.onmessage = null;
                ws.onclose = null;
                ws.onopen = null;
                ws.close();
                setWs(null);
                console.log('Chat closed')
            }
        }
    }, [])  

    useEffect(() => {
        if (receivedMessage['event_type'] === 'send') {                              
            setMessages(val => [...val, receivedMessage['message']]);
        } 
        if (receivedMessage['event_type'] === 'edit') {                              
            setMessages(val => SetDict(val, [val.findIndex(elem => elem?.id === receivedMessage['message_id'])], receivedMessage['message']));
        } 
        if (receivedMessage['event_type'] === 'read') {
            (messages ?? []).forEach((elem, index) => {
                setMessages(val => SetDict(val, [index, 'is_read'], true));
            }) 
        } 
        if (receivedMessage['event_type'] === 'delete') { 
            setMessages(val => val.filter(elem => elem?.id !== receivedMessage['message_id']));
        }         
    }, [receivedMessage])

    useEffect(() => {
        if (ws) {
            ws.onmessage = (e : MessageEvent) => {                              
                const message = JSON.parse(e.data);
                setReceivedMessage(message);  
            }
            ws.onclose = (e: CloseEvent) => {
                setTimeout(() => {
                    setWs(new WebSocket(`${GetWebsocketUrl()}/${id}`));
                }, 1000)
            }
            ws.onopen = e => {
                setWsEstablished(true);
            }
        } else {
            setWs(new WebSocket(`${GetWebsocketUrl()}/${id}`));
        }
    }, [ws]);

    useEffect(() => {
        if (profile?.links?.email) {
            Smartlook.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })

            posthog.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })
        }
    }, [profile?.links?.email ?? null])

    return (<div className={`body column noscroll`} style={{backgroundColor: globalContext.isTablet ? `#FFFFFF` : undefined}}>
        <div className={`w-flex`}>
            <div className={`block block p-3 pb-2 pt-2 w-flex`} data-color={`light-gray`} style={{
                position: 'fixed',
                zIndex: 48,
                boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.04)`
            }}>
                <div className={`row nowrap nogap w-flex`}>
                    <button className={`btn mobile row left nowrap noscroll w-4`} onClick={() => {navigate(`/profile/chat`)}}>
                        <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                    </button>
                    {loaded ?
                    <a href={`/profile/${interlocutor?.unique_id ?? interlocutor?.id}?from=chat&chat_id=${id}`} className={`mobile col-7`}>
                        <p className={`text-5 semibold center`} data-color={`black`}
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            {interlocutor?.name ?? ``}
                        </p>
                    </a> : 
                    <div className={`skeleton h-3 w-7 b-3`}></div>} 
                    {loaded ?
                    <a href={`/profile/${interlocutor?.unique_id ?? interlocutor?.id}?from=chat`} className={`mobile`}>
                        <NewAvatar size={4} type={interlocutor?.avatar?.style ?? 0} name={interlocutor?.name ?? ``}
                                    avatar={interlocutor?.avatar?.link}/></a> :
                    <div className={`skeleton w-4 h-4 b-1`}></div>}
                </div>
            </div>

            <div className={`w-flex pt-6`} onClick={() => { setControlMessage(false) }}>
            {loaded ? <>
                {!!messages && messages.map((elem, index) => {return (<div className={'w-flex'} key={index}>
                    {(!index || DateFromString(elem['created_at']) !== DateFromString(messages[index - 1]['created_at'])) && 
                    <div className={`row center my-2`}>
                        <div className={`block label btn-circled-24 mobile`} data-color={`black-20-opacity`}>
                            <p className={'text-6'} data-color={'white'}>{DateFromString(elem['created_at'])}</p>
                        </div>
                    </div>}
                    <div className={`block row px-3 pt pb ${elem['from_user'] === cookie['user_id_cookie'] ? 'right' : 'left'} top w-flex nogap nowrap noscroll ${messages[index + 1]?.from_user === elem.from_user ? "" : "mb-1"} message`}
                         data-color={controlMessage === elem.id ? 'black-5-opacity' : 'transparent'}>
                        <div className={`block b-2 p-2`} style={{
                            width: 'calc(100% - 50px)',
                            backgroundColor: cookie[`user_id_cookie`] === elem.from_user ? (controlMessage !== elem.id ? `#E7EAF7` : `#DBDEEA`) : undefined, 
                            borderRadius: messages[index + 1]?.from_user !== elem.from_user ? (elem['from_user'] === cookie['user_id_cookie'] ? `14px 14px 0 14px` : `14px 14px 14px 0`) : undefined,
                        }} data-color={globalContext.isTablet ? `light-gray` : null}
                        onClick={cookie[`user_id_cookie`] === elem.from_user ? e => {
                            e.stopPropagation();
                            setControlMessage((val: string | null) => val ? null : elem.id); 
                        } : undefined}>
                            {!elem.from_user &&
                            <div className="row left gap-1 nowrap mb-1">
                                <div className="w-3 h-3 b" style={{
                                    backgroundImage: "url(\"https://osmos-bucket.s3.eu-north-1.amazonaws.com/manual/default_avatar.jpg\")",
                                }}></div>
                                <p data-size={5} className="semibold seminarrow">{"Osmos"}</p>
                            </div>}
                            <p className={`text-6 mb-1`} style={{
                                overflowWrap: 'break-word',
                            }} data-color={`black`}>
                                {(elem?.content ?? '').trim().split('\n').map((elem_, index) => {return (
                                    <Fragment key={index}>
                                        {elem_.split(URLRegExp).filter(elem => !!elem).map((elem__, index__) => {return (
                                            URLRegExp.test(elem__) ?
                                            <a key={index__} onClick={e => { e.stopPropagation() }} style={{
                                                fontWeight: 400,
                                                position: `relative`,
                                                zIndex: 2,
                                            }} href={MakeValidURL(elem__)} target={`_blank`}>
                                                <span data-color={`blue`}>{elem__}</span>
                                            </a> :
                                            <span key={index__} style={{
                                                fontWeight: 400,
                                            }}>
                                                {elem__}
                                            </span>
                                        )})}
                                        <br/>
                                    </Fragment>
                                )})}
                            </p>
                            {!!elem?.reply_to &&
                            <FeedRequestCardMobile request={elem?.reply_to} onboardingV={true}/>}
                            <div className={`row nowrap noscroll`}>
                                <div className={`row nogap nowrap noscroll`}>
                                </div>
                                <div className={`row nowrap nogap noscroll`}>
                                    <p className={`text-6`} data-color={`black-40-opacity`}>{TimeFromString(elem['created_at'])}</p>
                                    {elem[`from_user`] === cookie['user_id_cookie'] &&
                                    <div className={`w-3 h-3 b-2 centered`}>
                                        <img className={`w-2`} src={elem[`is_read`] ? OkGreen : OkGray} alt={`v`}></img>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)})}
            </> : <>
                <div className={`row right top w-flex nogap nowrap noscroll mb-2`}>
                    <div className={`skeleton b-3 p-2 pr-3 pl-3`} style={{width: 'calc(100% - 32px)', height: 100}}></div>
                </div>
                <div className={`row left top w-flex nogap nowrap noscroll mb-2`}>
                    <div className={`skeleton b-3 p-2 pr-3 pl-3`} style={{width: 'calc(100% - 32px)', height: 100}}></div>
                </div>
            </>}
            </div>
        </div>
        
        <div className={`block sticky-bottom w-flex p-3 xs-py-1`} data-color={`light-gray`}
             style={{
                boxShadow: `0px -1px 2px 0px rgba(0, 0, 0, 0.04)`,
                zIndex: 48,
            }}>
            {!controlMessage || !!editMessageId ? <>
            <form className={`block b-2 row bottom nowrap nogap noscroll`} 
                onSubmit={e => {
                        e.preventDefault();
                        onSubmit(e);
                }}>
                <div className={`chat-input mobile`}>
                    <p className={`w-flex`}>
                        {newMessage.split('\n').map((elem: string, index: number) => {return (<Fragment key={index}>
                            {elem}<br/>
                        </Fragment>)})}
                    </p>                
                    <textarea className={`block b-2 w-flex h-flex`} data-size={6} 
                        data-color={`white`} placeholder={translate_messages[globalContext.locale].message} 
                        value={newMessage} 
                        onChange={e => { 
                            setNewMessage(e.target.value); 
                        }} onFocus={() => {
                            setShowSendBtn(true);
                        }} onBlur={() => {
                            setShowSendBtn(!!newMessage);
                        }} ref={chatInput}></textarea>
                </div>
                {showSendBtn &&
                <button type={`submit`} className={`btn mobile h-3 my-2`} style={{marginLeft: -38, position: `relative`}}>
                    <img src={SendMsgBlack} alt={``} className={`w-3 h-3 mr-2`} 
                         style={{opacity: profile?.subscription?.is_subscription_active === false ? 0.4 : undefined}}></img>
                </button>}
            </form> 
            
            {profile?.subscription?.is_subscription_active === false && !!newMessage && 
            <p data-size={7} data-color={`coral`} className={`pt px`}>
                {`You cannot send messages until your`}<br/>{`subscription is not active.`}
            </p>}
            </>:
            <div className={`row w-flex`}>
                <button type={`submit`} className={`btn mobile centered block b-2`} data-color={`black-5-opacity`} style={{height: 52, width: 52}}
                        onClick={() => { setControlMessage(null) }}>
                    <div className={`btn-symbol close filled centered h-3 w-3`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
                <div className={`row left`}>
                    <button type={`submit`} className={`btn mobile centered block b-2`} data-color={`black-5-opacity`} style={{height: 52, width: 52}}
                            onClick={() => { 
                                setEditMessageId(controlMessage);
                            }}>
                        <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                    </button>
                    <button type={`submit`} className={`btn mobile centered block b-2`} data-color={`black-5-opacity`} style={{height: 52, width: 52}}
                            onClick={() => { 
                                setDeleteMessageId(controlMessage);
                            }}>
                        <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                    </button>
                </div>
            </div>}    
        </div>

        {deleteMessageId &&
        <ConfirmPopup   title={`Delete message?`} subtitle={<FormattedMessage id="confirm_delete_message"/>}
                        show={deleteMessageId} setShow={setDeleteMessageId}
                        color_2={'light-gray'} buttonTitle_2={<FormattedMessage id="cancel"/>} onClick_2={() => {}} black={false} desktop={false}
                        color_1={'coral'} buttonTitle_1={<FormattedMessage id="delete"/>} onClick_1={() => {
                            if (ws?.readyState === 1) {
                                setDeleteMessageId(null);
                                setControlMessage(null);
                                ws.send(JSON.stringify({
                                    'event_type': 'delete',
                                    'message_id': deleteMessageId,
                                }));
                            }
                        }} close={'both'}/>}
    </div>)
}

export {
    ChatListMobile, 
    OfferListMobile,
    ChatMobile,
}