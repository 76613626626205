import dayjs from "dayjs";
import 'dayjs/locale/ru';
import { 
    AlexMAvatar,
    EvgOAvatar,
    KrishnaSAvatar,
    OsmosM, 
    OsmosMBlack, 
    OsmosMBlue, 
    OsmosMGreen, 
    OsmosMPink, 
    OsmosO, 
    OsmosOBlack, 
    OsmosOBlue, 
    OsmosOGreen, 
    OsmosOPink, 
    OsmosS, 
    OsmosSBlack, 
    OsmosSBlue, 
    OsmosSGreen,
    OsmosSPink,
    StarBlack,
    StarWhite
} from "./Imgs";

import Innovators from '../../imgs/innovators_logo.svg';
import InnovatorsBlack from '../../imgs/innovators_logo_black.svg';
import InnovatorsTop from '../../imgs/innovators_logo_top.svg';
import InnovatorsTopBlack from '../../imgs/innovators_logo_top_black.svg';

import MMCO from '../../imgs/mmco_logo.svg';
import MMCOBlack from '../../imgs/mmco_logo_black.svg';
import MMCOTop from '../../imgs/mmco_logo.svg';
import MMCOTopBlack from '../../imgs/mmco_logo_black.svg';
import MMCOProfileAfterAbout from '../../imgs/profile_after_about_mmco_v3.png';

import InnovatorsBg from '../../imgs/innovators_bg.png';
// import Taver from '../../imgs/taver_logo.svg';
// import TaverBlack from '../../imgs/taver_logo_black.svg';

declare global {
    interface Window {
        dataLayer: any;
    }
}

// const URLRegExp = /([-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&//=]*)?|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&//=]*))/gi;
const URLRegExp = /([-a-zA-Z0-9@:%_~#=]{1,256}(?:\.[a-zA-Z0-9()]{1,256})*\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&//=]*)?|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%_~#=]{1,256}(?:\.[a-zA-Z0-9()]{1,256})*\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&//=]*))/gi;
const UUIDRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const EmailRegExp = /^[\w.+]+@[a-zA-Z_]+?\.[a-zA-Z]{2,}$/;
const UniqueIdRegExp = /^(?:\w+[-_])+(?:\w+)$/;

const Strings = () => {
    const stringsStr = sessionStorage.getItem('strings');
    return stringsStr ? JSON.parse(stringsStr) : 
    {
        requests: [
            {
                title: `Looking for professionals to share experiences`,
                questions: [
                    `What area are you interested in?`,
                    `What experience and skills are you looking for in the people you'd like to meet?`,
                ],
                placeholder: "I would like to meet people who have experience in this and that...",
            },
            {
                title: `I am looking for a job`,
                questions: [
                    `What positions are you interested in?`,
                    `Please specify your experience, skills, and achievements.`,
                ],
                placeholder: "I am looking for a position in…",
            },
            {
                title: `I am looking for employees`,
                questions: [
                    `What kind of employee are you looking for?`,
                    `What skills and experience should they have?`,
                    `Which project is this for?`,
                ],
                placeholder: "I am looking for professionals with experience in…",
            },
            {
                title: `I am looking for clients`,
                questions: [
                    `What kind of clients are you looking for?`,
                    `What should they be interested in?`,
                ],
                placeholder: "I am looking for people or businesses interested in...",
            },
            {
                title: `I am looking for investments`,
                questions: [
                    `For which project are you seeking investments?`,
                    `What investors are you most interested in?`,
                ],
                placeholder: "I am looking for investors interested in... at this stage...",
            },
            {
                title: `Considering businesses for investment`,
                questions: [
                    `What projects are you interested in investing in?`,
                    `What field do you focus on?`,
                    `Do you have any experience in investing?`,
                ],
                placeholder: "I am looking for teams working in... at this stage...",
            },
            {
                title: `Looking for experts/consultants`,
                questions: [
                    `What field are you looking for experts or consultants in?`,
                    `What area do you want to understand better?`,
                ],
                placeholder: "I am looking for professionals to help me and my business with...",
            },
            {
                title: `Looking for partners for a project`,
                questions: [
                    `What individuals or organizations are you interested in partnering with?`,
                    `What is the objective?`,
                ],
                placeholder: "I'm currently on the lookout for companies and individuals who are also interested in collaborating in specific field...",
            },
            {
                title: `I want to expand my network`,
                questions: [
                    `With what kind of people would you be interested in meeting?`,
                    `What topics would you like to discuss with them?`,
                ],
                placeholder: "I would like to meet people who are...",
            },
            {
                title: `Other`,
                questions: [
                    `You've selected "Other". Who would you be interested to meet?`,
                    `What you'd like to discuss?`,
                ],
                placeholder: "I am interested in meeting people who do...",
            },
        ],
        statuses: [
            {
                title: 'Specify your request',
                description: 'What kind of people you would like to connect with regarding your current business goals? This will help us start looking for the best match for you',
                button: 'Specify your request',
            },
            {
                title: 'Add your projects',
                description: 'Include at least one significant project you have completed, so that other professionals can better understand your experience',
                button: 'Add project',
            },
            {
                title: 'Tell about yourself',
                description: 'Introduce yourself in a way that will pique the interest of the people you are looking for. This is the final step before we present you with your first match',
                button: 'Edit',
            },
            {
                title: 'Review your profile',
                description: 'It seems that your requests, projects, or information do not reflect the type of person that the people you are seeking to connect with would be interested in.\nPlease edit your profile accordingly.',
                button: 'Ok, got it',
            },
        ],
        delete_reasons: [
            {
                title: `I have a duplicate account`,
            },
            {
                title: `I'm getting too many emails`,
            },
            {
                title: `I'm not getting any value from my membership`,
            },
            {
                title: `I have a privacy concern`,
            },
            {
                title: `I'm receiving unwanted contact`,
            },
            {
                title: `Other`,
            },
        ] 
    }
}

const RequestExamples = [
    {
        color: `green`,
        name: `Jon Sofield`,
        description: `I am interested to speak with Owner Operators, Management teams and investment companies in the retirement living space.`,
        job: `Foresight Ventures`,
        avatar: ``,
        answers: [`I am interested to speak with Owner Operators, Management teams and investment companies in the retirement living space.`, "", ""],
        style: {
            background: 0,
        },
        user: {
            name: `Jon Sofield`,
            occupation: `Foresight Ventures`,
            industry: [`Digita Media`, `Tech`, `Media/Content`],
            avatar: {
                link: ``,
                style: [`green`, `light-pink`],  
             }
        }
    },
    {
        color: `pink`,
        name: `Evgenia Olerinskaya`,
        description: `I'd love to help founders exponentially grow their digital businesses through business development, digital marketing and a touch of my personal magic ;)`,
        job: `Industry Head, Global Expansion, Google`,
        avatar: EvgOAvatar,
        answers: [`I'd love to help founders exponentially grow their digital businesses through business development, digital marketing and a touch of my personal magic ;)`, "", ""],
        style: {
            background: 8,
        },
        user: {
            name: `Evgenia Olerinskaya`,
            occupation: `Industry Head, Global Expansion, Google`,
            industry: [`Impact`, `Consulting`, `Tech`],
            avatar: {
                link: EvgOAvatar,
                style: 0,   
            }
        }
    },
    {
        color: `yellow`,
        name: `Aleksandr Rakitin`,
        description: `I'm looking for early-stage co-founding partners to solve sustainability / climate tech problems.\nI don't have well-defined problems in mind yet, but would love to ideate with the right people. Ideal partners would be a CEO and a CTO.`,
        job: `Schneider Electric`,
        avatar: ``,
        answers: [`I'm looking for early-stage co-founding partners to solve sustainability / climate tech problems.\nI don't have well-defined problems in mind yet, but would love to ideate with the right people. Ideal partners would be a CEO and a CTO.`, "", ""],
        style: {
            background: 4,
        },
        user: {
            name: `Aleksandr Rakitin`,
            occupation: `Schneider Electric`,
            industry: [`EnergyTech`, `ClimateTech/CleanTech`, `DeepTech`],
            avatar: {
                link: ``,
                style: 0,   
            }
        }
    },
    {
        color: `light-blue`,
        name: `Krishna Sunkammurali`,
        description: `I am looking to network with healthcare professionals/experts that I can learn from.`,
        job: `CVS Health`,
        avatar: KrishnaSAvatar,
        answers: [`I am looking to network with healthcare professionals/experts that I can learn from.`, "", ""],
        style: {
            background: 5,
        },
        user: {
            name: `Krishna Sunkammurali`,
            occupation: `CVS Health`,
            industry: [`Impact`, `Tech`, `Digital Health`],
            avatar: {
                link: KrishnaSAvatar,
                style: 0,   
            }
        }
    },
    {
        color: `blue`,
        name: `Alex Mashrabov`,
        description: `I am looking for investors who want to fund foundational GenAI tech.`,
        job: `Snap Inc`,
        avatar: AlexMAvatar,
        answers: [`I am looking for investors who want to fund foundational GenAI tech.`, "", ""],
        style: {
            background: 7,
        },
        user: {
            name: `Alex Mashrabov`,
            occupation: `Snap Inc`,
            industry: [`AI`, `Tech`, `Generative Tech`],
            avatar: {
                link: AlexMAvatar,
                style: 0,   
            }
        }
    },
    {
        color: `light-yellow`,
        name: `Ruslan Stefanov`,
        description: `I am looking for a cybersecurity leading position in SOCs, software development and others. Companies specializing in cybersecurity products and services are preferred.`,
        job: `Honeywell`,
        avatar: ``,
        answers: [`I am looking for a cybersecurity leading position in SOCs, software development and others. Companies specializing in cybersecurity products and services are preferred.`, "", ""],
        style: {
            background: 2,
        },
        user: {
            name: `Ruslan Stefanov`,
            occupation: `Honeywell`,
            industry: [`Cybersecurity`, `Impact`, `Tech`],
            avatar: {
                link: ``,
                style: [`blue`, `light-yellow`],  
             }
        }
    },
]

// const Industries = [
//     `Advertising`, `AgTech`, `AI`, `Vc`, `Analytics`, `AR/VR`, `AudioTech`, 
//     `AutoTech`, `BioTech`, `Chemicals`, `ClimateTech/CleanTech`, 
//     `Cloud Infrastructure`, `ConstructionTech`, `Consumer Health`, 
//     `Consumer Internet`, `Cosmetics`, `Creator/Passion Economy`, `Cybersecurity`, 
//     `Data Science`, `DeepTech`, `Developer Tools`, `Diagnostics`, 
//     `Digital Health`, `Direct-to-Consumer (DTC)`, `Drug Delivery`, `E-commerce`, 
//     `Education`, `EnergyTech`, `Enterprise`, `Enterprise Application`, 
//     `Enterprise Infrastructure`, `Entertainment & Sports`, `Fashion`, `FinTech`, 
//     `Food and Beverage`, `Future of Work`, `Games`, `Gaming/eSports`, 
//     `Generative Tech/AI`, `Gig Economy`, `GovTech`, `Hardware`, 
//     `Health & Hospital Services`, `Health IT`, `Human Capital/HRTech`, `Impact`, 
//     `Insurance`, `IoT`, `LegalTech`, `Local Services`, `Lodging/Hospitality`, 
//     `Logistics`, `Manufacturing`, `MarketingTech`, `Marketplace`, 
//     `Material Science`, `Media/Content`, `Medical Device`, `Messaging`, 
//     `Parenting/Families`, `Payments`, `Pharmaceuticals`, `Real Estate/PropTech`, 
//     `Retail`, `Robotics`, `SaaS`, `Sales & CRM`, `Security`, `Semiconductors`,  
//     `Smart Cities/UrbanTech`, `SMB Software`, `Social Commerce`, 
//     `Social Networks`, `Space`, `Supply Chain Tech`, `Therapeutics`,
//     `TransportationTech`, `Travel`, `Web3/Blockchain`, `Web3/Crypto`, 
//     `Wellness & Fitness`, 
// ]

const Industries = (community?: string) => {
    const defaultList = [
        '3D Printing', 'Advertising', 'AgTech', 'AI', 'Autonomous Cars', 
        'AutoTech', 'AR/VR', 'AudioTech', 'Behavioral Science', 
        'Biometrics', 'BioTech', 'Blockchain', 'Chemicals', 
        'ClimateTech/CleanTech', 'Cloud Infrastructure', 'Communications and Networking', 
        'ConstructionTech', 'Consumer Health', 'Consumer Internet', 
        'Cosmetics', 'Creator/Passion Economy', 'Cybersecurity', 
        'Data Science', 'DeepTech', 'Developer Tools', 'Diagnostics', 
        'Digital Health', 'Digital Media', 'Direct-to-Consumer (DTC)', 
        'Drug Delivery', 'E-commerce', 'EdTech', 'Education',
        'Electronic Devices', 'Energy', 'EnergyTech',
        'Enterprise', 'Enterprise Application', 'Enterprise Infrastructure',
        'Entertainment & Sports', 'Fashion', 'FinTech',
        'Food and Beverage', 'FoodTech', 'Future of Work',
        'Games', 'Gaming/eSports', 'Generative Tech/AI',
        'Gig Economy', 'GovTech', 'Hardware',
        'Health & Hospital Services', 'Health IT',
        'Human Capital/HRTech', 'Impact',
        'Infrastructure', 'Insurance',
        'IoT', 'LegalTech',
        'LIDAR', 'Local Services',
        'Logistics', 'Manufacturing',
        'MarketingTech', 'Marketplace',
        'Material Science', 'Media/Content',
        'Medical AI', 'Medical Device',
        'MedTech', 'Messaging',
        'Parenting/Families', 'Payments',
        'Pharmaceuticals', 'Professional Networking Technology',
        'Real Estate/PropTech', 'Renewable Energy',
        'Retail', 'Robotics',
        'SaaS', 'Sales & CRM',
        'Security',  'Semiconductors',
        'Smart Cities/UrbanTech', 'SMB Software',
        'Social Commerce', 'Social Media',
        'Social Networks', 'Space',
        'SportsTech', 'Supply Chain Tech',
        'Therapeutics', 'Transportation',
        'TransportationTech', 'Travel',
        'Vertical Agriculture', 'Wearable Technology',
        'Water Technology', 'Web3/Crypto',
        'Web3/Blockchain', 'Wellness & Fitness'
    ]

    return community ? (CommunityLogo[community]?.industries ?? defaultList) : defaultList; 
}

const CreateHSLColor = (text: string) => {
    const code = [...text.trim()].map(
        char => char.toLowerCase().charCodeAt(0) - 96,
    ).reduce((acc, num) => acc + num)
    return `hsl(${(code % 90) * 4}deg ${(text.split(` `)?.length ?? 2) * 25}% 50%)`
}

const ScreenColors = [
    ['light-blue', 'white', 'black', `#F2F5FF`],   // 0
    ['light-yellow', 'coral', 'black', `#F2F5EB`], // 1
    ['light-pink', 'coral', 'black', `#F7F2FF`],   // 2
    ['pink', 'yellow', 'white', `#F5F5F5`],        // 3
    ['green', 'blue', 'white', `#F5F5F5`],         // 4
    ['blue', 'green', 'white', `#F5F5F5`],         // 5
    ['yellow', 'coral', 'black', `#F5F5F5`],       // 6
    ['coral', 'yellow', 'white', `#F5F5F5`],       // 7
    ['black', 'light-blue', 'white', `#F5F5F5`],   // 8
]

const RequestColors = [
    ['green', 'blue', 'white', `white`],                      // 0
    ['light-blue', 'white', 'black-40-opacity', `black`],     // 1
    ['light-yellow', 'coral', 'black-40-opacity', `black`],   // 2
    ['light-pink', 'coral', 'black-40-opacity', `black`],     // 3
    ['yellow', 'coral', 'black-40-opacity', `black`],         // 4
    ['bright-blue', 'coral', 'white', `white`],               // 5
    ['bright-pink', 'green', 'white', `white`],               // 6
    ['blue', 'green', 'white', `white`],                      // 7
    ['pink', 'yellow', 'white', `white`],                     // 8
]

const RequestColors2 = [
    {color: 'green', secondaryColor: 'blue', textColor: 'white', textSecondaryColor: `white`, bgOpacity: 0.1},                      // 0
    {color: 'light-blue', secondaryColor: 'white', textColor: 'black-40-opacity', textSecondaryColor: `black`, bgOpacity: 0.4},     // 1
    {color: 'light-yellow', secondaryColor: 'coral', textColor: 'black-40-opacity', textSecondaryColor: `black`, bgOpacity: 0.4},   // 2
    {color: 'light-pink', secondaryColor: 'coral', textColor: 'black-40-opacity', textSecondaryColor: `black`, bgOpacity: 0.4},     // 3
    {color: 'yellow', secondaryColor: 'coral', textColor: 'black-40-opacity', textSecondaryColor: `black`, bgOpacity: 0.2},         // 4
    {color: 'bright-blue', secondaryColor: 'coral', textColor: 'white', textSecondaryColor: `white`, bgOpacity: 0.15},               // 5
    {color: 'bright-pink', secondaryColor: 'green', textColor: 'white', textSecondaryColor: `white`, bgOpacity: 0.15},               // 6
    {color: 'blue', secondaryColor: 'green', textColor: 'white', textSecondaryColor: `white`, bgOpacity: 0.1},                      // 7
    {color: 'pink', secondaryColor: 'yellow', textColor: 'white', textSecondaryColor: `white`, bgOpacity: 0.1},                     // 8
]

const LogoLetters: {[key: string]: any} = {
    'black': {
        'O': OsmosOBlack,
        'S': OsmosSBlack,
        'M': OsmosMBlack,
    },
    'white': {
        'O': OsmosO,
        'S': OsmosS,
        'M': OsmosM,
    },
    'green': {
        'O': OsmosOGreen,
        'S': OsmosSGreen,
        'M': OsmosMGreen,
    },
    'blue': {
        'O': OsmosOBlue,
        'S': OsmosSBlue,
        'M': OsmosMBlue,
    },
    'pink': {
        'O': OsmosOPink,
        'S': OsmosSPink,
        'M': OsmosMPink,
    },
}

const ScreenColorsDict: {[key: string]: string[]} = {
    'white': ['black', 'black'],
    'gray': ['black', 'black'],
    'dark-gray': ['white', 'white'],
    'green': ['blue', 'white'],
    'black': ['light-blue', 'white'],
    'blue': ['green', 'white'],
    'coral': ['yellow', 'white'],
    'yellow': ['coral', 'black'],
    'pink': ['yellow', 'white'],
    'light-pink': ['coral', 'black'],
    'bright-pink': [`white`, `black`],
    'light-blue': ['white', 'black'],
    'bright-blue': [`white`, `black`],
    'light-yellow': ['coral', 'black'],
    'transparent': ['black', 'white'],
    'light-gray': ['black', 'black'],
    'black-10-opacity': ['black', 'black'],
    'black-40-opacity': ['white', 'white'],
    'black-5-opacity': ['black', 'black'],
    'white-40-opacity': ['black', 'black'],
    'tp-white': ['black', 'black'],
}

export const CommunityLogo: {[key: string]: any} = {
    innovators: {
        title: "Innovators",
        white: Innovators,
        black: InnovatorsBlack,
        topIcon: InnovatorsTop,
        topIconBlack: InnovatorsTopBlack,
        ogImage: InnovatorsTopBlack,
        bg: InnovatorsBg,
        support_email: "support@innovators.club",
        noreply_email: "noreply@innovators.club",
        description: "The place where visioners meet",
        ogTitle: "innovators.club",
        favicon: "https://cdn.prod.website-files.com/653569c53268205b9fade672/653572c5dfd7d237956cf8d6_Frame%201321314362.png",
        color: "black",
        url: "https://innovators.club",
        text: "Connect with new partners, collaborate on creative projects, and share your ideas!\n\nReady to join - fill your form!",
    },
    mmco: {
        title: "MMCO",
        white: MMCO, 
        black: MMCOBlack, 
        topIcon: MMCOTop, 
        topIconBlack: MMCOTopBlack, 
        support_email: "support@mmco-expo.ru",
        noreply_email: "noreply@mmco-expo.ru",
        description: "Ваш навигатор в сфере образования.",
        ogTitle: "MMCO",
        favicon: "https://mmco-expo.ru/templates/online/images/favicon.svg",
        color: "black",
        url: "https://mmco-expo.ru",
        redirect_on_logout: false,
        custom_login: {
            title: "MMCO.ID",
            redirect_url: "https://mmco-expo.ru/my/cabinet",
        },
        redirect_on_edit: "https://mmco-expo.ru/my/cabinet",
        info_api: true,
        hide_marketing_settings: true,
        locale: "ru",
        industries: [
            "#развитие_педагога",
            "#качество_образования",
            "#экономика_будущего",
            "#образовательная_политика",
            "#гуманитарное_образование",
            "#уникальные_концепции",
            "#коммуникация",
            "#образовательная_среда",
            "#инклюзия",
            "#технологии_для_образования",
            "#EdTech",
            "#дошкольное_образование",
            "#школа",
            "#колледж",
            "#университет",
            "#доп_образование",
            "#ДПО",
        ],
        show_all_industries: true,
        text: "Добро пожаловать на платформу для нетворкинга сообщества ММСО!\n\nЗдесь профессионалы могут встретиться с руководителями компаний, инвесторами и ведущими экспертами, которые, как и вы, настроены на качественное общение и взаимную поддержку.",
        custom_blocks: {
            profile_after_about: {
                type: "image",
                image_url: MMCOProfileAfterAbout,
                redirect_url: "https://mmco-expo.ru"
            }
        },
        custom_color: "mmco-color",
    },
    // taver: {
    //     white: Taver,
    //     black: TaverBlack,
    //     text: "We're delighted to welcome you to the networking platform for Taver Capital's portfolio companies. You can use this space to connect with new partners, engage in business collaborations, and discuss topics of interest to you!",
    // },
    test: {
        white: StarWhite,
        black: StarBlack,
        text: "We're delighted to welcome you to the networking platform of Osmos.Test. You can use this space to connect with new partners, engage in business collaborations, and discuss topics of interest to you!",
    },
    qa2: {
        title: "QA_TESTING",
        white: MMCO, 
        black: MMCOBlack, 
        topIcon: MMCOTop, 
        topIconBlack: MMCOTopBlack, 
        // white: StarWhite,
        // black: StarBlack,
        // topIcon: StarWhite,
        // topIconBlack: StarBlack,
        bg: InnovatorsBg,
        description: "This is your description",
        ogTitle: "QA OSMOS SOCIAL",
        favicon: "https://cdn.prod.website-files.com/653569c53268205b9fade672/653572c5dfd7d237956cf8d6_Frame%201321314362.png",
        color: "green",
        support_email: "support@mmco-expo.ru",
        noreply_email: "noreply@mmco-expo.ru",
        url: "https://google.com",
        locale: "ru",
        custom_login: {
            title: "MMCO.ID",
            redirect_url: "https://mmco-expo.ru/my/cabinet",
            enable_google: true,
        },
        text: "Мы рады приветствовать вас на платформе для нетворкинга ММСО!\n\nЗдесь вы можете знакомиться с коллегами, находить деловых партнеров и обсуждать интересующие вас темы!",
        custom_blocks: {
            profile_after_about: {
                type: "image",
                image_url: MMCOProfileAfterAbout,
                redirect_url: "https://mmco-expo.ru"
            }
        },
        custom_color: "mmco-color",
    }
}

const ProfileStyles = [
    {
        color: 'blue',
        colorOne: 'transparent',
        colorTwo: 'transparent',  
        transparentLetters: true,      
    },
    {
        color: 'yellow',
        colorOne: 'transparent',
        colorTwo: 'transparent',        
    },
    {
        color: 'yellow',
        colorOne: 'blue',
        colorTwo: 'light-gray',        
    },
    {
        color: 'coral',
        colorOne: 'bright-pink',
        colorTwo: 'dark-gray',        
    },
    {
        color: 'pink',
        colorOne: 'yellow',
        colorTwo: 'green',        
    },
    {
        color: 'blue',
        colorOne: 'bright-blue',
        colorTwo: 'blue',        
    },
    {
        color: 'blue',
        colorOne: 'yellow',
        colorTwo: 'bright-pink',        
    },
    {
        color: 'coral',
        colorOne: 'green',
        colorTwo: 'light-gray',        
    },
    {
        color: 'coral',
        colorOne: 'pink',
        colorTwo: 'bright-pink',        
    },
    {
        color: 'pink',
        colorOne: 'bright-blue',
        colorTwo: 'yellow',        
    },
    {
        color: 'green',
        colorOne: 'dark-gray',
        colorTwo: 'yellow',        
    },
    {
        color: 'pink',
        colorOne: 'bright-pink',
        colorTwo: 'green',        
    },
    {
        color: 'green',
        colorOne: 'dark-gray',
        colorTwo: 'bright-blue',        
    },
]

const BlogLayout = {
    desktop: [
        {
            title: `px-2 mb-2`,
            subtitle: `px-2 mb`,
            text: `px-2 mb-3`,
            image: `mb-4`,
            closing: `px-2`,
        },
        {
            title: `px-2 mb-2`,
            subtitle: `px-2 mb`,
            text: `px-2 mb-2`,
            image: `mb-3`,
            closing: `px-2`,
        },
    ],
    mobile: [
        {
            title: `px mb-2`,
            subtitle: `px mb`,
            text: `px mb-2`,
            image: `mb-3`,
            closing: `px`,
        },
        {
            title: `px mb-2`,
            subtitle: `px mb`,
            text: `px mb-2`,
            image: `mb-3`,
            closing: `px`,
        },
    ],
}

export function MergeArrays(a: any[], b: any[], bool: boolean = false) {
    return bool ? a.concat(b.filter((val: any) => !a.includes(val))) :
        a.filter((val: any) => !b.includes(val)).concat(b)
}

export function MatchTimestamp() {
    const current_date = new Date()
    const nearest_day = new Date(current_date.getTime() + ((8 - current_date.getDay()) % 7) * 24 * 3600 * 1000)
    const result =  new Date(
        nearest_day.getFullYear(),
        nearest_day.getMonth(),
        nearest_day.getDate(),
        15
    ).getTime() / 1000 - current_date.getTimezoneOffset() * 60

    return current_date.getTime() > result * 1000 ? result + 7 * 24 * 3600 : result
}

export function UntilMatch() {
    const isRus = sessionStorage.getItem("locale") === 'ru'
    const current_ts = new Date().getTime() / 1000;
    const days = Math.floor((MatchTimestamp() - current_ts) / 3600 / 24);
    const hours = Math.floor(((MatchTimestamp() - current_ts) / 3600) % 24)
    return {
        days: days,
        hours: hours,
        text_: (days === 0 && hours === 0) ? (isRus ? "менее чем через час" : "less than an hour") : 
        (days === 0 && hours > 0) ? (isRus ? 
            `через ${hours} ${
                (hours % 10 === 1 && hours % 100 !== 11) ? "час" :
                (hours % 10 < 5 && ![12, 13, 14].includes(hours % 100)) ? "часа" : "часов"
            }` : 
            `${hours} hour${hours > 1 ? "s" : ""}`) :
        (days > 0 && hours === 0) ? (isRus ? 
            `через ${days} ${
                (days % 10 === 1 && days % 100 !== 11) ? "день" :
                (days % 10 < 5 && ![12, 13, 14].includes(days % 100)) ? "дня" : "дней"
            }` : 
            `${days} day${days > 1 ? "s" : ""}`) :
        `${days} day${days > 1 ? "s" : ""} and ${hours} hour${hours > 1 ? "s" : ""}`,
        text: (days === 0) ? (isRus ? "менее чем через сутки" : "less than a day") : (isRus ? 
            `через ${days} ${
                (days % 10 === 1 && days % 100 !== 11) ? "день" :
                (days % 10 < 5 && ![12, 13, 14].includes(days % 100)) ? "дня" : "дней"
            }` : 
            `${days} day${days > 1 ? "s" : ""}`),
    }
}

export function Until(date?: string | number | null) {
    if (!date) {
        return {
            weeks: Infinity,
            w_days: Infinity,
            days: Infinity,
            hours: Infinity,
            minutes: Infinity,
        }
    } else {
        const ts = DateToTimestamp(date);
        const delta = ts - new Date().getTime() / 1000;
        return delta < 0 ? {
            weeks: Infinity,
            w_days: Infinity,
            days: Infinity,
            hours: Infinity,
            minutes: Infinity,
        } : {
            weeks: Math.floor(delta / 3600 / 24 / 7),
            w_days: Math.floor(delta / 3600 / 24) % 7,
            days: Math.floor(delta / 3600 / 24),
            hours: Math.floor(delta / 3600) % 24,
            minutes: Math.floor(delta / 60) & 60,
        }
    }
}

function IsClosedBlock(
    page: number,
    tab: number,
) {
    return (page === 0 && [5].includes(tab)) || ([3, 4].includes(page)) || typeof page !== 'number' || typeof tab !== `number`
}

function FirstSelected(
    list: any[], 
    selected: any[]
) {
    return list.filter(val => selected.includes(val)).concat(
        list.filter(val => !selected.includes(val))
    )
}

function GoTo(
    url: string, 
    target: string = '_blank'
) {
    const newWindow: WindowProxy | null = window.open(url, target);
    if (newWindow) {
        newWindow.focus();
    }
}

function SetDict(
    dict: any, 
    keys: Array<string | number>, 
    value: any, 
    index: number = 0
): any {
    if (keys[index] !== undefined) {
        if (typeof keys[index] === "string") {
            return {
                ...dict ?? {},
                [keys[index]]: SetDict((dict ?? {})[keys[index]], keys, value, index=index+1),
            } 
        } else if (typeof keys[index] === "number") {
            if (keys[index] === (dict?.length ?? 0)) {
                return (dict ?? []).concat(SetDict((dict ?? [])[keys[index]], keys, value, index=index+1));
            } else {
                return (dict ?? []).map((element: any, ind: number) => {
                    if (ind === keys[index]){
                        return SetDict((dict ?? [])[keys[index]], keys, value, index=index+1);
                    } else {
                        return element;
                    }
                }) 
            } 
        }
    } else {
        return value    
    }
} 

function SetCollapse() {
    document.querySelectorAll(`.collapse[data-open="true"]:not([data-height="meet"])`).forEach((elem: Element) => {
        (elem as HTMLDivElement).style.maxHeight = `${Math.min(elem.scrollHeight, Number(elem.getAttribute('data-collapse-height') ?? 1000000))}px`;
    });
    document.querySelectorAll('.collapse:not([data-open="true"])').forEach((elem: Element) => {
        (elem as HTMLDivElement).style.maxHeight = `unset`;
        elem.scrollTop = 0;
    });
}

function Sticky(
    top: number, 
    zIndex: number = 0, 
    direction: string = 'top',
) {
    return {
        position: 'sticky',
        top: direction === 'top' ? `${top}px` : undefined,
        bottom: direction === 'bottom' ? `${top}px` : undefined,
        zIndex: zIndex,
    }
}

function Capitals(
    name: string,
) {
    if (!name || typeof name !== 'string') {
        return ['', '']
    } else if (name.replace(/\s+/g, ' ').split(' ').length > 1) {
        return [
            name.replace(/\s+/g, ' ').split(' ')[0].toUpperCase()[0],
            name.replace(/\s+/g, ' ').split(' ')[1].toUpperCase()[0]
        ]
    } else if (name.replace(/\s+/g, ' ').split(' ').length === 1){
        return [
            name.replace(/\s+/g, ' ').toUpperCase()[0],
            name.replace(/\s+/g, ' ').toUpperCase()[0]
        ]
    } else {
        return ['', '']
    }
}

async function ImgColor(
    img: File | string
) {
    const context = document.createElement('canvas').getContext('2d');
    if (typeof img == 'string') {
        var src = img;
        const new_img = new Image();
        new_img.setAttribute('crossOrigin', ''); 
        new_img.src = src;        
        return new Promise(resolve => {
            new_img.onload = async () => {
                if (context) {
                    context.imageSmoothingEnabled = true;
                    context.drawImage(new_img, 0, 0, 1, 1);
                    const color = context.getImageData(0, 0, 1, 1).data;
                    resolve("#" + ((1 << 24) + (color[0] << 16) + (color[1] << 8) + color[2]).toString(16).slice(1));
                } else {
                    resolve(`#000000`);
                }
            }
            new_img.onerror = () => {
                resolve(`#000000`);
            }
        })
    } 
}

function TextColor(
    color: string,
) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    if (result) {
        const rgb = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        const hsp = Math.sqrt(
            0.299 * (rgb.r * rgb.r) +
            0.587 * (rgb.g * rgb.g) +
            0.114 * (rgb.b * rgb.b)
        );
        if (hsp > 127.5) {
            return ('black');
        } else {
            return ('white');
        }
    } else {
        return ('black'); 
    }
}

function GetWebsocketUrl() {
    return `${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/api/chat`
}

async function GetOG(
    url: string
): Promise<any> {
    return new Promise(resolve => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}html`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({url: url}),
        }).then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    console.log('HTML', data);
    
                    resolve({
                        status: true,
                        title: data?.title ?? "",
                        preview: MakeProperURL(MakeValidURL(data?.site_name ?? url), data?.image ?? ""),
                        description: data?.description ?? "",
                        url: MakeValidURL(data?.site_name ?? url),
                    })
                }).catch(e => {
                    console.warn('HTML', res);
                    resolve({
                        title: "", preview: "", description: "", url: "", status: false,
                    })
                })
            } else {
                console.error('HTML', res);
                resolve({
                    title: "", preview: "", description: "", url: "", status: false,
                })
            }
        }).catch(e => {            
            resolve({
                title: "", preview: "", description: "", url: "", status: false,
            })
        })
    })

}

function TimeFromString(
    timestamp: string | number,
) {
    if (timestamp) {
        const date = new Date(DateToTimestamp(timestamp) * 1000);
        return dayjs(date).format('HH:mm')
    } else {
        return '';
    }
}

function DateFromString(
    timestamp: string | number,
) {
    dayjs.locale(sessionStorage.getItem("locale") ?? "en");
    if (timestamp) {
        const date = new Date(DateToTimestamp(timestamp) * 1000);
        return dayjs(date).format(`MMM D${dayjs(date).format(`YYYY`) !== dayjs(new Date(Date.now())).format(`YYYY`) ? ' YYYY' : ''}`);
    } else {
        return '';
    }
}

function OtherDayTimeFromString(
    timestamp: string | number,
) {
    if (timestamp) {
        const date = new Date(DateToTimestamp(timestamp) * 1000);

        if (Number(date.getTime()) + 7 * 24 * 3600 * 1000 > Number((new Date(Date.now())).getTime())) {
            return dayjs(date).format('ddd HH:mm');
        } else {
            return dayjs(date).format('MMM D');
        }
    } else {
        return '';
    }
}

function DateTimeFromString(
    timestamp: string | number,
) {
    if (timestamp) {
        const date = new Date(DateToTimestamp(timestamp) * 1000);
        return dayjs(date).format('D MMM HH:mm');
    } else {
        return '';
    }
}

function DateToTimestamp(
    date: string | number,
): number {
    if (date) {
        return typeof date === 'string' ? Date.parse(`${date}Z`) / 1000 : date;
    } else {
        return 0;
    }
}

function MakeValidURL(
    url: string,
): string {
    if (!/^https?:\/\//i.test(url) 
        && !("https://").startsWith(url) && !("http://").startsWith(url) 
            && url) {
       return 'https://' + url;
    } else {
        return url;
    }
}

function MakeNiceURL(
    url: string,
): string {
    return url.replace(/^https?:\/\//g, '');
}

function MakeProperURL(
    url: string, 
    imgUrl: string,
) {
    const origin = (new URL(url ?? `https://osmos.social?nologin=true`)).origin;

    if (!(/^https?:\/\//).test(imgUrl) || imgUrl.startsWith('/')) {
        return origin + imgUrl;
    } else {
        return imgUrl;
    }
}

function IsURL(
    url: string,
) {
    return (/^https?:\/\/.+\.\w+/).test(url ?? '');
}

function IsFilled(
    card: any,
) {
    for (let index = 0; index < Strings().requests[card?.type ?? 0].questions.length; index++) {
        if (card.answers[index]) {
            return true
        } 
    }

    return false
}

function OnlyValid(
    cards: any[],
) {
    return (cards ?? []).filter(val => !!(val.answers.filter((val: string) => !!val).length));
}

function OnlyValidUsers(
    users: any[],
) {
    return (users ?? []).filter(
        val => OnlyValid(val.requests)?.length > 0 
        && !!val.name && !!val.occupation
    );
}

const PushHistory = (
    path: string | null,
): void => {
    if (path) {
        if (window.history.state?.pageName !== path) {
            window.history.pushState({pageName: path}, "", `/profile/${path}`);
        } 
    }
}

const LogOut = (
    redirect: string | null = null,
    no_redirect: boolean = false,
) => {
    sessionStorage.clear();
    localStorage.clear();
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('profile_');
    sessionStorage.removeItem('meetings');
    sessionStorage.removeItem('cards');
    sessionStorage.removeItem('projects');
    localStorage.removeItem(`read_notifications`);

    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}logout`, request)
    .then(res => {
        if (res.status === 200) {
            console.log('LogOut', res);
        } else {
            console.warn('LogOut', res);
        }
        if (!no_redirect) {
            window.location.href = `${window.location.origin}?force_logout=true${redirect ? `&redirect=${redirect}` : ``}`;
        }
    })    
    .catch(error => {
        console.error('LogOut', {ok: false, status: -1, statusText: error});
        if (!no_redirect) {
            window.location.href = `${window.location.origin}?force_logout=true${redirect ? `&redirect=${redirect}` : ``}`;
        }
    })
}

const GetLocationByIP = (
    setLoc: Function = () => {}, 
    ip: string | null = null
) => {
    const request = {
        method: 'GET',
    }
    fetch(`${process.env.REACT_APP_BACKEND_URL}ipapi`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                console.log('GetLoc', res);
                setLoc({
                    city: data.city,
                    country: data.country,
                })
                return {
                    city: data.city,
                    country: data.country,
                }
            }).catch(error => {
                console.warn('GetLoc', {ok: false, status: 200, statusText: error});
                return {
                    city: "",
                    country: "",
                }
            })
        } else {
            console.warn('GetLoc', res);
            return {
                city: "",
                country: "",
            }
        }
    })
    .catch(error => {
        console.error(`GetLoc`, {ok: false, status: -1, statusText: error});
        return {
            city: "",
            country: "",
        }
    })
} 

function getXPathForElement(
    element: Node, 
) {
    const docElem = document.documentElement;

    let el: Node | null = element;
    let xPath: string = "";
    let pos: number;
    let tempItem: Node | null;
  
    while (el && el !== docElem && (el as Element)?.id !== `root`) {
        pos = 0;
        tempItem = el;
        while (tempItem) {
            if (tempItem.nodeType === 1 && tempItem.nodeName === el?.nodeName) {
                // If it is ELEMENT_NODE of the same name
                pos += 1;
            }
            tempItem = tempItem.previousSibling;
        }
  
        xPath = `${el?.nodeName.toLowerCase()}${(el as Element)?.id ? `[@id='${
            (el as Element)?.id ?? ""
        }']` : ""}[${pos}]/${xPath}`;

        console.log(el, (el as Element)?.parentNode)
  
        el = (el as Element)?.parentNode;
    }

    if (el) {
        xPath = `//*${el?.nodeName.toLowerCase()}${(el as Element)?.id ? `[@id='${
            (el as Element)?.id ?? ""
        }']` : ""}/${xPath}`;
    }

    xPath = xPath.replace(/\/$/, "");
    return xPath;
}

function IsLeftScroll(
    elem: Element,
) {
    return elem.scrollLeft > 0;
}

function IsRightScroll(
    elem: Element,
) {
    return elem.scrollLeft + 1 < elem.scrollWidth - elem.clientWidth;
}

function SetMetaData(
    profile: {
        id: string,
        name: string,
        occupation: string,
        unique_id?: string,
    }
) {
    document.title = `${profile?.name} — ${profile?.occupation} | Profile`;

    const descMeta = document.querySelector('meta[name="description"]')    
    if (descMeta) {
        descMeta.setAttribute("content", profile.occupation);
    }
    const ogDescMeta = document.querySelector('meta[property="og:description"]')    
    if (ogDescMeta) {
        ogDescMeta.setAttribute("content", profile.occupation);
    }
    const ogTitleMeta = document.querySelector('meta[property="og:title"]')    
    if (ogTitleMeta) {
        ogTitleMeta.setAttribute("content", profile.name);
    }
    const ogUrlMeta = document.querySelector('meta[property="og:url"]')    
    if (ogUrlMeta) {
        ogUrlMeta.setAttribute("content", `${document.location.origin}/profile/${profile?.unique_id ?? profile.id}`);
    }
}

function SetCommunityMetadata(
    community: string | null
) {
    document.title = community ? 
        (CommunityLogo[community]?.ogTitle ?? 
            CommunityLogo[community]?.title ??
                "Osmos — Meet the right crowd. Get things done.") :
        "Osmos — Meet the right crowd. Get things done.";

    // if (CommunityLogo[community]?.ogTitle) {
    //     document.title = CommunityLogo[community]?.ogTitle
    // } else if (CommunityLogo[community]?.title) {
    //     document.title = CommunityLogo[community]?.title
    // }

    // const descMeta = document.querySelector('meta[name="description"]')    
    // if (descMeta && CommunityLogo[community]?.description) {
    //     descMeta.setAttribute("content", CommunityLogo[community]?.description);
    // }
    // const ogDescMeta = document.querySelector('meta[property="og:description"]')    
    // if (ogDescMeta && CommunityLogo[community]?.description) {
    //     ogDescMeta.setAttribute("content", CommunityLogo[community]?.description);
    // }
    // const ogTitleMeta = document.querySelector('meta[property="og:title"]')    
    // if (ogTitleMeta && CommunityLogo[community]?.ogTitle) {
    //     ogTitleMeta.setAttribute("content", CommunityLogo[community]?.ogTitle);
    // }
    // const ogUrlMeta = document.querySelector('meta[property="og:url"]')    
    // if (ogUrlMeta && CommunityLogo[community]?.url) {
    //     ogUrlMeta.setAttribute("content", CommunityLogo[community]?.url);
    // }
    // const ogImageMeta = document.querySelector('meta[property="og:image"]')    
    // if (ogImageMeta && CommunityLogo[community]?.ogImage) {
    //     ogImageMeta.setAttribute("content", CommunityLogo[community]?.ogImage);
    // }
    // if (CommunityLogo[community]?.favicon) {
    //     let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    //     if (!link) {
    //         link = document.createElement('link');
    //         link.rel = 'icon';
    //         document.head.appendChild(link);
    //     }
    //     link.href = CommunityLogo[community]?.favicon;
    // }

    const descMeta = document.querySelector('meta[name="description"]')    
    if (descMeta) {
        descMeta.setAttribute(
            "content", 
            community ? 
                (CommunityLogo[community]?.description ??
                    "Beyond just another social network. Osmos AI: Tailored matches aligned with professional goals. Discover conversations, not just contacts.") :
                "Beyond just another social network. Osmos AI: Tailored matches aligned with professional goals. Discover conversations, not just contacts."
        );
    }
    const ogDescMeta = document.querySelector('meta[property="og:description"]')    
    if (ogDescMeta) {
        ogDescMeta.setAttribute(
            "content", 
            community ? 
                (CommunityLogo[community]?.description ??
                    "Beyond just another social network. Osmos AI: Tailored matches aligned with professional goals. Discover conversations, not just contacts.") :
                "Beyond just another social network. Osmos AI: Tailored matches aligned with professional goals. Discover conversations, not just contacts."
        );
    }
    const ogTitleMeta = document.querySelector('meta[property="og:title"]')    
    if (ogTitleMeta) {
        ogTitleMeta.setAttribute(
            "content", 
            community ?
                (CommunityLogo[community]?.ogTitle ??
                    "Osmos — Meet the right crowd. Get things done.") :
                "Osmos — Meet the right crowd. Get things done."                
        );
    }
    const ogUrlMeta = document.querySelector('meta[property="og:url"]')    
    if (ogUrlMeta) {
        ogUrlMeta.setAttribute(
            "content", 
            community ? 
                (CommunityLogo[community]?.url ??
                    "https://my.osmos.social") :
                "https://my.osmos.social"
        );
    }
    const ogImageMeta = document.querySelector('meta[property="og:image"]')    
    if (ogImageMeta) {
        ogImageMeta.setAttribute(
            "content", 
            community ? 
                (CommunityLogo[community]?.ogImage ??
                    "https://my.osmos.social/logo512.png") :
                "https://my.osmos.social/logo512.png"
        );
    }
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }
    link.href = community ? 
        (CommunityLogo[community]?.favicon ??
            "%PUBLIC_URL%/favicon.ico") : 
        "%PUBLIC_URL%/favicon.ico";

    document.documentElement.lang = community ? CommunityLogo[community]?.locale ?? "en" : "en";
}

function ReadNotification(
    notification: {
        type: number,
        from_user: string,
        target_id: string,
    }, 
    setUnread: boolean = false) {
    const read_notifications = JSON.parse(localStorage.getItem(`read_notifications`) ?? `[]`);
    const notification_index = read_notifications.findIndex((val: any) => (
        val?.type === notification?.type &&
        val?.from_user === notification?.from_user &&
        val?.target_id === notification?.target_id
    ))

    if (setUnread) {
        if (notification_index > -1) {
            localStorage.setItem(`read_notifications`, JSON.stringify(
                read_notifications.filter((val: any, index: number) => index !== notification_index)
            ));
        }
    } else {
        try {
            localStorage.setItem(`read_notifications`, JSON.stringify([
                ...read_notifications,
                {
                    type: notification?.type,
                    from_user: notification?.from_user,
                    target_id: notification?.target_id,
                },
            ]));
        } catch (error) {
            console.warn(`Storage error: ${error}`);
            localStorage.setItem(`read_notifications`, JSON.stringify([{
                type: notification?.type,
                from_user: notification?.from_user,
                target_id: notification?.target_id,
            }]));
        }
    }
}

function IsReadNotification(
    notification: {
        type: number,
        from_user: string,
        target_id: string,
    }
) {
    const read_notifications = JSON.parse(localStorage.getItem(`read_notifications`) ?? `[]`);
    const notification_index = read_notifications.findIndex((val: any) => (
        val?.type === notification?.type &&
        val?.from_user === notification?.from_user &&
        val?.target_id === notification?.target_id
    ))

    return notification_index > -1;
}

function IsThisProfile(id: string, profile: any) {
    return profile?.id === id || profile?.unique_id === id
}

function ClipBoard(
    text: string, 
    onOk: Function
) {
    if (window.isSecureContext) {
        navigator.clipboard.writeText(text)
        .then(() => {
            onOk();
        })
        .catch(err => {
            console.warn(`Copy text error: ${err}`)
            navigator.permissions.query({ name: "clipboard-write" as PermissionName }).then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    console.warn(result);
                }
            });
        })
    } else {
        console.warn(`Secure context: ${window.isSecureContext}`)
    }
}

function Share(
    text: string,
    onClipboard: Function,
) {
    if (window.isSecureContext) {
        navigator.share({
            title: "Osmos",
            url: text,
        }).catch(error => {
            navigator.clipboard.writeText(text)
            .then(() => {
                onClipboard();
            })
            .catch(err => {
                console.warn(`Copy text error: ${err}`)
                navigator.permissions.query({ name: "clipboard-write" as PermissionName }).then((result) => {
                    if (result.state === "granted" || result.state === "prompt") {
                        console.warn(result);
                    }
                });
            })
        })
    } else {
        console.warn(`Secure context: ${window.isSecureContext}`)
    }
}

const DescriptionLevel = (raw_text: string) => {
    const text = (raw_text ?? "").trim().replace("\n", "");
    const style = {
        backgroundColor: `color-mix(in hsl, #0BC582 ${text?.length / 3.5}%, #E25C57)`,
        width: `${text?.length / 3.5}%`
    }

    if ((/[а-яА-Я]/).exec(text) && sessionStorage.getItem("locale") !== 'ru') {
        return {
            isText: true, style: {
                backgroundColor: `#E25C57`,
                width: `${text?.length / 3.5}%`
            }, text: 'To broaden your match possibilities, ensure you only use the English language', isValid: false,
            locale_text: "ob_onlylatin",
        };
    } else if (!text?.length) {
        return {
            isText: false, style: {}, text: '', isValid: false,
        };
    } else if (text?.length < 50) {
        return {
            isText: true, style: style, isValid: false, 
            text: 'Your bio seems a bit short, could you add some more details?',
            locale_text: "ob_intro_short_1",
        };
    } else if (text?.length < 200) {
        return {
            isText: true, style: style, isValid: true,
            text: 'Heading in the right direction! Yet, we could benefit from more details.',
            locale_text: "ob_intro_short_2",
        };
    } else {
        return {
            isText: true, style: style, isValid: true, 
            text: 'Your bio looks great, thank you for sharing!',
            locale_text: "ob_intro_short_ok",
        };
    }
}

const RequestsLevel = (raw_text: string) => {
    const text = (raw_text ?? "").trim().replace("\n", "");;
    const style = {
        backgroundColor: `color-mix(in hsl, #0BC582 ${text?.length / 3.5}%, #E25C57)`,
        width: `${text?.length / 3.5}%`
    }

    if ((/[а-яА-Я]/).exec(text) && sessionStorage.getItem("locale") !== 'ru') {
        return {
            isText: true, style: {
                backgroundColor: `#E25C57`,
                width: `${text?.length / 3.5}%`
            }, text: 'To broaden your match possibilities, ensure you only use the English language', isValid: false,
            locale_text: "ob_onlylatin",
        };
    } else if (!text?.length) {
        return {
            isText: false, style: {}, text: '', isValid: false,
        };
    } else if (text?.length < 20) {
        return {
            isText: true, style: style, isValid: false, 
            text: 'Oops, your request is a bit brief! Mind adding more details?',
            locale_text: "add_request_intro_short_1",
        };
    } else if (text?.length < 200) {
        return {
            isText: true, style: style, isValid: true,
            text: 'Heading in the right direction! Yet, we could benefit from more details.',
            locale_text: "add_request_intro_short_2",
        };
    } else {
        return {
            isText: true, style: style, isValid: true, 
            text: 'Great request, kudos!',
            locale_text: "add_request_intro_short_3",
        };
    }
}

const DataLayerPush = (data: object) => {
    if (window.location.host.startsWith("my.")) {
        window.dataLayer.push(data);
    }
}

const CheckLatin = (text: string) => {
    return Boolean((/[а-я]/).exec(text)) && sessionStorage.getItem("locale") !== 'ru';
}

export { 
    Strings,
    SetCollapse,
    SetDict,
    Sticky,
    Capitals,
    ImgColor,
    ScreenColors,
    RequestColors,
    RequestColors2,
    Industries,
    ScreenColorsDict,
    BlogLayout,
    GetWebsocketUrl,
    GetOG,
    TimeFromString,
    DateFromString,
    DateTimeFromString,
    OtherDayTimeFromString,
    ProfileStyles,
    TextColor,
    MakeValidURL,
    MakeNiceURL,
    IsURL,
    IsFilled,
    OnlyValid,
    OnlyValidUsers,
    PushHistory,
    LogOut,
    MakeProperURL,
    URLRegExp,
    UniqueIdRegExp,
    GetLocationByIP,
    getXPathForElement,
    DateToTimestamp,
    UUIDRegExp,
    EmailRegExp,
    IsLeftScroll,
    IsRightScroll,
    LogoLetters,
    RequestExamples,
    SetMetaData,
    SetCommunityMetadata,
    ReadNotification,
    IsReadNotification,
    IsThisProfile,
    ClipBoard,
    Share,
    GoTo,
    DescriptionLevel,
    RequestsLevel,
    DataLayerPush,
    CheckLatin,
    CreateHSLColor,
    FirstSelected,
    IsClosedBlock,
};
